// StarRating.jsx
import React from 'react';
import PropTypes from 'prop-types';

const StarRating = ({ rating, maxStars = 5, starSize = '1rem', fullStarColor = '#ffb100', emptyStarColor = '#d1d5dc' }) => {
  // Ensure rating is within bounds
  const normalizedRating = Math.max(0, Math.min(rating, maxStars));

  // Calculate full stars, partial star percentage, and empty stars
  const fullStars = Math.floor(normalizedRating);
  const partialStar = normalizedRating % 1; // Decimal part for partial star
  const emptyStars = maxStars - Math.ceil(normalizedRating);

  // Convert starSize to a number (removing 'rem' or 'px' for calculations)
  const sizeValue = parseFloat(starSize);

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        lineHeight: 1, // Ensure consistent line height for smaller sizes
      }}
    >
      {/* Full stars */}
      {[...Array(fullStars)].map((_, index) => (
        <span
          key={`full-${index}`}
          style={{
            fontSize: starSize,
            color: fullStarColor,
            lineHeight: 1,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <i className="fas fa-star" />
        </span>
      ))}

      {/* Partial star */}
      {partialStar > 0 && (
        <span
          style={{
            position: 'relative',
            fontSize: starSize,
            display: 'inline-flex',
            alignItems: 'center',
            width: starSize, // Ensure the container has a defined width
            height: starSize, // Ensure the container has a defined height
          }}
        >
          <i
            className="fas fa-star"
            style={{
              color: emptyStarColor,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <i
            className="fas fa-star"
            style={{
              color: fullStarColor,
              position: 'absolute',
              top: 0,
              left: 0,
              width: `${partialStar * 100}%`,
              overflow: 'hidden',
            }}
          />
        </span>
      )}

      {/* Empty stars */}
      {[...Array(emptyStars)].map((_, index) => (
        <span
          key={`empty-${index}`}
          style={{
            fontSize: starSize,
            color: emptyStarColor,
            lineHeight: 1,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <i className="fas fa-star" />
        </span>
      ))}
    </div>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  maxStars: PropTypes.number,
  starSize: PropTypes.string,
  fullStarColor: PropTypes.string,
  emptyStarColor: PropTypes.string,
};

export default StarRating;