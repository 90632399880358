import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainHeader from "../../Pages/Header/MainHeader";
import FooterStyle from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import API from '../../Api/Api';
import { IMAGE_PATHS, base64Encode, base64Decode, DOMAIN_URL } from "../../Functions/Constant";
const dummyData = [
    {
        name: "Ramandeep Singh",
        company: "Exoways Web Technologies Pvt. Ltd. | Full-time",
        duration: "Oct 2018 - Present 6 yrs 6 mon",
        location: "Chandigarh",
        image: "https://lithoreact.themezaa.com/assets/img/webp/avtar27.webp",
        relation: "Worked Together",
    },

];

const SearchForm = () => {
    const location = useLocation();
    const [users, setUsers] = useState([]);
    const [visibleItems, setVisibleItems] = useState(5);
    const [queryString, setQueryString] = useState({});
    const getQueryParams = () => {
        const searchParams = new URLSearchParams(location.search);
        const queryData = searchParams.get("query");

        try {
            return queryData ? JSON.parse(decodeURIComponent(queryData)) : {};
        } catch (error) {
            console.error("Error parsing JSON from query string:", error);
            return {};
        }
    };
    const fetchUserList = async (payload) => {
        const response = await API.post('/user/searchuser', payload);
        setUsers(response.users);
        console.log(response.users);
    }
    useEffect(() => {
        const searchParams = getQueryParams();
        setQueryString(searchParams);
        console.log(searchParams);
        fetchUserList(searchParams);
    }, [])
    const rateNow = async (user) => {
        const tag_data_id = queryString.search_type == 1 ? user.company_id : user.school_id;
        const payload = {
            user_id: user.user_id, type: queryString.search_type, tag_data_id: tag_data_id
        };
        const response = await API.post('/user/initiate-rating', payload);
        console.log(response);
        window.location.href = '/review_form/' + base64Encode(response.review_id);
    }

    const loadMore = () => {
        setVisibleItems((prev) => prev + 5);
    };

    return (
        <div>
            <MainHeader />
            <section className="pb-[220px] px-4 pt-[120px] custm-bg">
                <Container>
                    <h3 className="text-[32px] font-bold text-black mb-10 leading-[1.5]">
                        {users.length} results matching
                    </h3>

                    {users.slice(0, visibleItems).map((user, index) => (
                        <Row key={index} className="p-6 pt-0">
                            <Col lg={6} md={12} sm={12} className="custm-bg">
                                <div className="flex">
                                    <img
                                        src={user.profile_picture != null ? DOMAIN_URL + user.profile_picture : 'https://lithoreact.themezaa.com/assets/img/webp/avtar27.webp'}
                                        className="w-[80px] h-[80px] rounded-full object-cover border border-gray-300"
                                        alt={user.name}
                                    />
                                    <div className="flex flex-col justify-center ml-4">
                                        <h4 className="text-lg font-bold text-gray-900 mb-0">
                                            {user.name}
                                        </h4>
                                        {
                                            queryString.search_type == 1 ? (
                                                <><p className="text-gray-700 leading-[1.6]">{user.company_name }</p>
                                                    <p className="text-gray-700 leading-[1.2]">{user.start_year}</p></>
                                            ) : (<><p className="text-gray-700 leading-[1.6]">{user.school_name }</p>
                                                <p className="text-gray-700 leading-[1.2]">{user.school_year}</p></>)
                                        }

                                        <p className="text-gray-700">{user.city_name}</p>

                                    </div>
                                </div>
                            </Col>

                            <Col lg={6} md={12} sm={12} className="flex justify-end items-start">
                                <button className="btn-explore-white" onClick={() => rateNow(user)}>Review Now</button>
                            </Col>
                        </Row>
                    ))}

                    {visibleItems < dummyData.length && (
                        <div className="text-center mt-4">
                            <button
                                className="text-lg font-semibold text-gray-600 cursor-pointer"
                                onClick={loadMore}
                            >
                                Load more search
                            </button>
                        </div>
                    )}
                </Container>
            </section>
            <FooterStyle theme="light" className="bg-black text-[#828282]" />
        </div>
    );
};

export default SearchForm;
