import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";

// Home
import Home from "./Pages/Home";
import EmployeeDetail from "./Pages/EmployeeDetail";
import LoginRegister from "./Pages/LoginRegister";
import WriteReview from "./Pages/WriteReview";
import ReviewForm from "./Pages/ComponentPage/ReviewForm";
import Profile from "./Pages/ComponentPage/Profile";
import AuthSuccess from "./Functions/AuthSuccess";
import DownloadPdf from "./Pages/ComponentPage/DownloadPdf";
import Privary from "./Pages/Privary";
import TermPolicy from "./Pages/TermPolicy";
import Feeds from "./Pages/Feeds";
import Chatbot from "./Pages/ComponentPage/Chatbot";
import SearchResult from "./Pages/ComponentPage/SearchResult";
import ThemeDesign from "./Pages/ThemeDesign";
const NotFoundPage = lazy(() => import("./Pages/404"));



function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence mode="wait">
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/employee_detail"
                    element={
                      <EmployeeDetail style={{ "--base-color": "#374162" }} />
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <LoginRegister style={{ "--base-color": "#374162" }} />
                    }
                  />
                  <Route
                    path="/write_review"
                    element={
                      <WriteReview style={{ "--base-color": "#374162" }} />
                    }
                  />
                  <Route
                    path="/review_form/:id"
                    element={
                      <ReviewForm style={{ "--base-color": "#374162" }} />
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <Profile style={{ "--base-color": "#374162" }} />
                    }
                  />
                  <Route
                    path="/auth-success"
                    element={
                      <AuthSuccess style={{ "--base-color": "#374162" }} />
                    }
                  />
                   <Route
                    path="/download_pdf"
                    element={
                      <DownloadPdf style={{ "--base-color": "#374162" }} />
                    }
                  />
                   <Route
                    path="/privacy"
                    element={
                      <Privary style={{ "--base-color": "#374162" }} />
                    }
                  />
                   <Route
                    path="/term_policy"
                    element={
                      <TermPolicy style={{ "--base-color": "#374162" }} />
                    }
                  />
                   <Route
                    path="/feeds"
                    element={
                      <Feeds style={{ "--base-color": "#374162" }} />
                    }
                  />
                  <Route
                    path="/chatbot"
                    element={
                      <Chatbot style={{ "--base-color": "#374162" }} />
                    }
                  />
                  <Route
                    path="/search_result"
                    element={
                      <SearchResult style={{ "--base-color": "#374162" }} />
                    }
                  />
                  <Route path="/theme-design" element={<ThemeDesign />} />
                  <Route path="*" element={<NotFoundPage />} />
                  
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
