import React, { useState, useRef, useEffect } from 'react';
import './AddPhotoModal.css';

const AddPhotoModal = ({ onClose, onPhotoSelect, imageSrcVal }) => {
  const [useCamera, setUseCamera] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  // Check if webcam is available and set up the stream
  useEffect(() => {
    if (useCamera) {
      const startCamera = async () => {
        try {
          const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
          setStream(mediaStream);
          if (videoRef.current) {
            videoRef.current.srcObject = mediaStream;
            videoRef.current.play();
          }
        } catch (error) {
          setCameraError('Failed to access webcam: ' + error.message);
          setUseCamera(false);
        }
      };
      startCamera();
    }

    // Cleanup: Stop the camera stream when the component unmounts or camera is closed
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [useCamera, stream]);

  // Handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const photoURL = URL.createObjectURL(file);
      onPhotoSelect(photoURL);
      onClose();
    }
  };

  // Capture photo from webcam
  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const photoURL = canvas.toDataURL('image/png');
      onPhotoSelect(photoURL);
      onClose();
    }
  };

  // Toggle between camera and file upload
  const toggleCamera = () => {
    if (useCamera && stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
    setUseCamera(!useCamera);
    setCameraError(null);
  };

  return (
    <div >
      {cameraError && <div className="error-message">{cameraError}</div>}

      {!useCamera ? (
        <div className="upload-option">
          <input
            type="file"
            id="photoUpload"
            accept="image/*"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
          <div className="flex justify-center mt-[82px] mb-[160px]">
            <div className="w-[150px] h-[150px] rounded-full  overflow-hidden">
              <img
                src={imageSrcVal}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          <div className="absolute bottom-0 left-0 w-full bg-white p-4 border-t border-gray-300 flex gap-2 rounded-b-lg  justify-end">
           
            <label htmlFor="photoUpload" className="btn-explore-white">
                Upload from Directory
              </label>
              <button onClick={toggleCamera} className="btn-explore-yellow">
                Use Camera
              </button>
          </div>

        </div>
      ) : (

        <div className="absolute bottom-0 left-0 w-full bg-white p-4 border-t border-gray-300 flex gap-2 rounded-b-lg  justify-end">
          <video ref={videoRef} className="camera-preview" />
          <canvas ref={canvasRef} style={{ display: 'none' }} />
          <div className="camera-controls">
            <button className="btn-explore-white " onClick={capturePhoto}>
              Capture Photo
            </button>
            <button className="btn-explore-yellow" onClick={toggleCamera}  >
              Switch to Upload
            </button>
          </div>
        </div>
      )}
    </div>

    
  );
};

export default AddPhotoModal;