import React, { memo } from "react";
import { Accordion } from "react-bootstrap";
import { m } from "framer-motion";
import PropTypes from "prop-types";
import "../../Assets/scss/components/_accordion.scss"; // Your styles
import { getStarColorClass, getRatingLabel } from '../../Functions/RatingLabelColor';

const SkillsAccordion = (props) => {
    return (
        <Accordion defaultActiveKey={0}>
            {props.data.map((skill, key) => (
                <m.div
                    className="accordion-item-wrapper bg-white border rounded-lg mb-3 shadow-md"
                    key={key}
                    {...{ ...props.animation, transition: { delay: key * props.animationDelay } }}
                >
                    <Accordion.Item eventKey={key}>
                        {/* Header Section */}
                        <Accordion.Header className="leading-[3]">
                            {/* Skill Title */}
                            <span className="font-bold text-gray-800 leading-[1.2]">{skill.title}</span>

                            {/* Progress Bar & Rating */}
                            <div className="flex items-center w-[55%] ml-auto">
                                <div className="w-full h-[12px] bg-gray-200 rounded-full overflow-hidden mx-3">
                                    <div className="h-full yellow-b" style={{ width: `${(skill.rating / 5) * 100}%` }}></div>
                                </div>
                            </div>
                            <div className="ml-auto mr-auto">
                                <span className="text-gray-800 text-[14px] font-semibold whitespace-nowrap">
                                    {skill.rating.toFixed(1)}
                                </span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-4 pt-0 ">
                            <div className="flex flex-wrap gap-2">
                                {skill.skills.map((detail, idx) => (
                                    <span
                                        key={idx}
                                        className="flex items-center px-2 py-0 text-sm font-medium text-black rounded-full gap-2 h-m bg-white"
                                    >
                                        <i className={`fas fa-star ${getStarColorClass(detail.rating)}`}></i>
                                        <span className="black-class">{detail.name} </span>
                                        <span className="text-gray-500">({detail.total})</span>
                                    </span>
                                ))}
                            </div>
                        </Accordion.Body>

                    </Accordion.Item>
                </m.div>
            ))}
        </Accordion>
    );
};

// Default props
SkillsAccordion.defaultProps = {
    animationDelay: 0.2,
    theme: "accordion-style-03",
    themeColor: "light",
    className: "",
};

// Prop validation
SkillsAccordion.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string.isRequired,
            rating: PropTypes.number.isRequired,
            details: PropTypes.arrayOf(PropTypes.string).isRequired,
        })
    ).isRequired,
    animation: PropTypes.object,
    animationDelay: PropTypes.number,
};

export default memo(SkillsAccordion);
