import React, { useRef, forwardRef, useImperativeHandle } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Container, Row, Col } from "react-bootstrap";
import { getStarColorClass, getRatingLabel } from '../../Functions/RatingLabelColor';
import { IMAGE_PATHS, DOMAIN_URL } from '../../Functions/Constant';

const ProfileReviewPDF = forwardRef((props, ref) => {
    const pdfRef = useRef();

    const downloadPDF = () => {
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Scope to the component's ref to avoid capturing unintended elements
        let elements = pdfRef.current.querySelectorAll(".pdf-section");
        let position = 5; // Reduced initial padding from the top

        const captureElement = (element, callback) => {
            html2canvas(element, {
                scale: 2,
                useCORS: true, // Handle external images
            }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const imgWidth = pdfWidth - 20; // Margins on both sides
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                if (position + imgHeight > pdfHeight - 5) {
                    pdf.addPage();
                    position = 5; // Reduced top padding on new pages
                }

                pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
                position += imgHeight + 2; // Reduced gap between sections from 10 to 2 mm

                callback();
            });
        };

        const processNext = (index) => {
            if (index < elements.length) {
                captureElement(elements[index], () => processNext(index + 1));
            } else {
                pdf.save("profile_review.pdf");
            }
        };

        // Ensure first section is captured properly
        if (elements.length > 0) {
            processNext(0);
        } else {
            alert("No content to generate PDF!");
        }
    };
    // Expose downloadPDF to the parent via ref
    useImperativeHandle(ref, () => ({
        downloadPDF,
    }));


    const professionalSkills = [
        {
            title: "Core Professional Skills",
            rating: 4.5,
            details: ["Communication Skills", "Team Collaboration", "Leadership", "Problem-Solving"]
        },
        {
            title: "Technical & Analytical Skills",
            rating: 4.7,
            details: ["Technical Proficiency", "Data Analysis", "Attention to Detail"]
        },
        {
            title: "Interpersonal & Soft Skills",
            rating: 4.2,
            details: ["Emotional Intelligence", "Conflict Resolution", "Networking"]
        },
        {
            title: "Creativity & Innovation",
            rating: 4.2,
            details: ["Creativity", "Innovation", "Design Thinking", "Entrepreneurial Mindset", "Adaptability to Change"]
        },
        {
            title: "Personal Effectiveness",
            rating: 4.2,
            details: ["Resilience", "Stress Management", "Continuous Learning", "Goal Orientation", "Self-Awareness"]
        }
    ];

    const userData = {
        profile_image: "https://via.placeholder.com/80",
        name: "Ikos Dassia",
        title: "Founder & CEO",
        contact: "Address | Phone | Email",
        experience: [
            { title: "Founder & CEO", company: "Exoways Web Technologies Pvt. Ltd.", duration: "Oct 2018 - Present", location: "Chandigarh, India" }
        ],
        education: [
            { institution: "Punjab Technical University", degree: "Master's Degree", field: "Information Technology", year: "2006 - 2010" }
        ],
        language: { name: "English", level: "Professional Working Proficiency" }
    };

    const ratings = {
        overall_rating: 4.5,
        total_reviews: 6548,
        excellent: 4609,
        very_good: 1298,
        average: 355,
        poor: 23,
        terrible: 3,
        core_skills: [
            { label: "Core Skills", value: 4.5 },
            { label: "Creativity & Innovation", value: 4.6 },
            { label: "Personal Effectiveness", value: 4.3 },
            { label: "Technical & Analytical Skills", value: 4.7 },
            { label: "Interpersonal Skills", value: 4.2 }
        ]
    };

    return (
        <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
            <Container ref={pdfRef} className=" rounded-md p-5 " id="pdf-content">
                {/* Profile Section */}
                <section className="pt-[20px]  pdf-section">
                    <Row className="items-end justify-between ">
                        <Col lg={6} md={12} sm={12}>
                            <div className="flex items-end space-x-6 mb-3">
                                <img
                                    src={IMAGE_PATHS.USER}
                                    alt="User"
                                    className="w-[80px] h-[80px] rounded-full object-cover border border-gray-300 relative top-[10px]"
                                />
                                <div className="flex-1 relative leading-[1.5]">
                                    <h1 className="text-[28px] font-bold text-black mb-0 leading-tight relative ">{userData.name}</h1>

                                    <div className="flex items-end ">
                                        {[...Array(5)].map((_, index) => (
                                            <span key={index} className={` ${index < ratings.overall_rating ? "yellow-class" : "text-gray-300"}`}>
                                                <i className="fas fa-star"></i>
                                            </span>
                                        ))}
                                        <span className="text-gray-700 font-medium ml-1">{ratings.total_reviews} Reviews</span>
                                    </div>

                                    <div className="flex space-x-6">
                                        <div><i className="fas fa-map-marker-alt mr-1 black-class"></i> <span className="text-gray-700">Dassia 49100</span></div>
                                        <div><i className="fas fa-phone mr-1 black-class"></i> <span className="text-gray-700">+918237410750</span></div>
                                        <div><i className="fas fa-envelope mr-1 black-class"></i> <span className="text-gray-700">abc@gmail.com</span></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} >
                            <div className="text-center flex justify-end mb-3">
                                <p className="font-medium text-[10px] text-[#000] mr-[10px] leading-[1.5]">Scan QR Code<br /> For  Detailed<br /> Career Report</p>
                                <img
                                    src="https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=https://example.com"
                                    alt="QR Code"
                                    className="w-[70px] h-[70px] border border-gray-300 rounded-md"
                                />
                            </div>
                        </Col>
                    </Row>

                    <section className="pt-[20px]">
                        <Container>
                            <Row className="align-items-baseline">
                                <Col lg={12} md={12} className="mb-6 md:mb-0 ">
                                    <h3 className="text-[28px] font-bold text-black mb-0 text-[#000]">Overall Rating</h3>
                                </Col>

                            </Row>
                            <Row className="justify-between items-center">
                                {/* Overall Rating Section (Left) */}
                                <Col lg={2} md={12} className="mb-6 md:mb-0 leading-[1.4]">
                                    <div>
                                        <div className="text-[50px] font-bold leading-[1.2] text-[#000]">{ratings.overall_rating.toFixed(1)}</div>
                                        <span className="font-medium text-[18px] text-[#000]">
                                            {ratings.overall_rating >= 4.5 ? "Excellent" :
                                                ratings.overall_rating >= 3.5 ? "Very Good" :
                                                    ratings.overall_rating >= 2.5 ? "Average" :
                                                        ratings.overall_rating >= 1.5 ? "Poor" : "Terrible"}
                                        </span>
                                        <div className="flex space-x-1 mt-0">
                                            {[...Array(5)].map((_, index) => (
                                                <span key={index} className={` ${index < ratings.overall_rating ? "yellow-class" : "text-gray-300"}`}>
                                                    <i className="fas fa-star"></i>
                                                </span>
                                            ))}
                                        </div>

                                        <span className="text-gray-600 text-[14px]">{ratings.total_reviews} Reviews</span>
                                    </div>
                                </Col>

                                <Col lg={4} md={12} sm={12} className="border-r-2 border-gray-300 leading-[1.8] pr-[45px]">
                                    {[
                                        { label: 'Excellent', value: ratings.excellent, max: ratings.total_reviews },
                                        { label: 'Very good', value: ratings.very_good, max: ratings.total_reviews },
                                        { label: 'Average', value: ratings.average, max: ratings.total_reviews },
                                        { label: 'Poor', value: ratings.poor, max: ratings.total_reviews },
                                        { label: 'Terrible', value: ratings.terrible, max: ratings.total_reviews },
                                    ].map((item, index) => (
                                        <div key={index} className="flex items-center space-x-2 w-full">

                                            {/* Label */}
                                            <div className="text-[#000] text-[14px] min-w-[80px]">{item.label}</div>

                                            {/* Progress Bar */}
                                            <div className="flex-1 h-[12px] bg-gray-300 rounded-full relative overflow-hidden top-[10px]">
                                                <div className="absolute top-0 left-0 h-full yellow-b rounded-full"
                                                    style={{ width: `${item.max ? (item.value / item.max) * 100 : 0}%` }}>
                                                </div>
                                            </div>

                                            {/* Value */}
                                            <div className="text-[#000] text-[14px] min-w-[40px] text-start pl-[8px] relative bottom-[2px]">{item.value}</div>

                                        </div>
                                    ))}
                                </Col>
                                <Col lg={6} md={12} sm={12} className="pl-[45px]">
                                    <div className="grid grid-cols-2 sm:grid-cols-2 gap-y-2 gap-x-4">
                                        {[
                                            { label: 'Core Skills', rating: 4.5 },
                                            { label: 'Creativity & Innovation', rating: 4.6 },
                                            { label: 'Personal Effectiveness', rating: 4.3 },
                                            { label: 'Technical & Analytical Skills', rating: 4.7 },
                                            { label: 'Interpersonal Skills', rating: 4.2 }
                                        ].map((item, index) => (
                                            <div key={index} className="flex flex-col justify-center items-start leading-[1]">
                                                <div className="leading-[1.4] flex items-center h-[20px]">
                                                    <span className="font-medium text-[14px] text-[#000]  pb-[18px] relative bottom-[4px]">{item.label}</span>
                                                </div>
                                                <div className="flex items-center w-full">
                                                    <div className="w-[78%] h-[12px] bg-gray-200 rounded-full overflow-hidden mr-2">
                                                        <div className="h-full yellow-b" style={{ width: `${(item.rating / 5) * 100}%` }}></div>
                                                    </div>
                                                    <div>
                                                        <span className="text-[#000] text-[14px] relative bottom-[12px]">{item.rating}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <section className=" pt-[20px] pb-[5px]  ">
                            <Container>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div className="flex justify-between items-baseline">
                                            <div>
                                                <h3 className="text-[28px] font-bold text-black  mb-3">Professional Reviews</h3>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="rounded-lg p-6 pt-0 border-1 bg-1 custm-bg shdo-box" >
                                    <h3 className="text-[24px] font-bold mb-0 text-[#000]">Summary</h3>
                                    <hr className="border-gray-300 mb-4 border-t-1" />
                                    <Col lg={6} md={12} className="mb-6">
                                        <div className="flex items-start space-x-3 mb-3">
                                            <div className="mt-[8px]">
                                                <span className="text-[40px] font-bold text-gray-900 relative top-[5px]">4.5</span>
                                            </div>
                                            <div className="leading-[1.4] relative bottom-[8px]">
                                                <span className="text-[16px] font-bold text-gray-900">Very Good</span>
                                                <div className="flex items-center  mb-2">
                                                    {[...Array(5)].map((_, index) => (
                                                        <i key={index} className="fas fa-star yellow-class text-[16px]"></i>
                                                    ))}
                                                    <span className="text-gray-600 text-[14px] ml-1">128 Reviews</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-[#74787F] text-white  px-4 py-0  rounded-[0.6rem] inline-block shadow-md w-fit text-center ">
                                            <div className="text-[14px] relative bottom-[12px] ">
                                                This summary was created by AI, based on recent reviews.
                                            </div>
                                        </div>
                                        <p className="text-gray-700 text-[14px] mt-3 leading-[1.5]">
                                            Hyatt Regency Dubai, situated in the heritage heart of the city,
                                            features 421 luxury hotel rooms & suites with great facilities,
                                            recreation center and meeting spaces. Overlooking the Arabian Gulf
                                            and the newly developed Dubai Islands, these spaces offer a calm
                                            atmosphere to work, play and relax.
                                        </p>

                                        <div className="mt-2">
                                            <h4 className="font-bold text-gray-900 text-[16px] mb-2">
                                                Key Strengths
                                            </h4>
                                            <div className="flex flex-wrap gap-1 relative top-[1px]">
                                                {["Focus", "Innovation", "Teamwork"].map((skill, index) => (
                                                    <span
                                                        key={index}
                                                        className="flex items-center px-2 py-0 text-sm  font-medium text-black rounded-full  gap-1 h-m bg-white"
                                                    >
                                                        <i key={index} className="fas fa-star green-i relative bottom-[12px]"></i>
                                                        <span className="black-class relative bottom-[12px]">4.5 {skill}</span>
                                                        <span className="relative bottom-[12px] text-gray-500">(10)</span>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <h4 className="font-bold text-gray-900 text-[16px] mb-2">
                                                Improvement Areas
                                            </h4>
                                            <div className="flex flex-wrap gap-1 relative top-[1px]">
                                                {["Creativity", "Public Speaking", "Creativity"].map((weakness, index) => (
                                                    <span
                                                        key={index}
                                                        className="flex items-center px-2 py-0  font-medium text-black text-sm rounded-full  gap-1 h-m bg-white"
                                                    >
                                                        <i key={index} className="fas fa-star red-i relative bottom-[12px]"></i>
                                                        <span className="black-class relative bottom-[12px]">1 {weakness}</span>
                                                        <span className="relative bottom-[12px] text-gray-500">(10)</span>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} className="">
                                        <div className="space-y-3">
                                            {professionalSkills.map((skill, key) => (
                                                <div
                                                    key={key}
                                                    className="bg-white border rounded-lg p-4 shadow-md"
                                                >
                                                    {/* Header Section */}
                                                    <div className="flex justify-between items-center mb-4">
                                                        {/* Skill Title */}
                                                        <span className="font-bold text-gray-800">{skill.title}</span>

                                                        {/* Progress Bar & Rating */}
                                                        <div className="flex items-center w-[54%]">
                                                            <div className="w-full h-[12px] bg-gray-200 rounded-full overflow-hidden mx-3 relative top-[12px]">
                                                                <div className="h-full yellow-b" style={{ width: `${(skill.rating / 5) * 100}%` }}></div>
                                                            </div>

                                                            <span className="text-gray-800 text-[14px] font-semibold whitespace-nowrap">
                                                                {skill.rating}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-wrap gap-1 ">
                                                        {skill.details.map((detail, idx) => (
                                                            <span
                                                                key={idx}
                                                                className="flex items-center px-3 py-0 text-[14px] font-medium bg-white gap-1 rounded-full h-m"
                                                            >
                                                                <i className={`fas fa-star relative bottom-[14px] ${getStarColorClass(skill.rating)} mr-2`}></i>
                                                                <span className="text-gray-900 relative bottom-[13px]">{detail}</span>
                                                                <span className="text-gray-500 ml-1 relative bottom-[13px]">(10)</span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </section>
                </section>
                <section className="pb-[15px] pdf-section">
                    <Container>
                        {/* Experience Header */}
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <h3 className="text-[22px] font-bold text-gray-900 mb-4 mt-2 leading-[1]">Experience</h3>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row className="bg-white rounded-lg p-6 border-1 ">
                            <Col lg={1} md={2} sm={12}>
                                <img
                                    src={IMAGE_PATHS.BUILDING}
                                    className="w-[60px] h-[60px] bg-gray-300 rounded-md relative top-[10px]"
                                />
                            </Col>
                            <Col lg={10} md={9} sm={12} className="leading-[1.4]">
                                <h4 className="text-lg font-bold text-gray-900 mb-0">Founder & CEO</h4>
                                <p className="text-gray-700 ">Exoways Web Technologies Pvt. Ltd. | Full-time</p>
                                <p className="text-gray-700">Oct 2018 - Present 6 yrs 6 mon</p>
                                <p className="text-gray-700">Chandigarh, India</p>
                                <p className="text-gray-700 mt-2">
                                    Study Abroad Test Prep Platform offering services to B2B & B2C Clients. We have a network of 2000 centres, majorly in India. We have network of centres in Australia, Nepal, Bangladesh, Canada.
                                </p>
                            </Col>

                            <hr className="border-gray-300 my-4 border-t-1" />
                            <Col lg={2} md={12} className="text-start">
                                <h1 className="text-[40px] font-bold text-gray-900 mb-0 leading-[1]">4.5</h1>
                                <p className="text-gray-600 font-semibold">Very Good</p>
                                <div className="yellow-i text-lg">
                                    {[...Array(5)].map((_, index) => (
                                        <i key={index} className="fas fa-star"></i>
                                    ))}
                                </div>
                                <p className="text-gray-500 text-sm">128 Reviews</p>
                            </Col>

                            {/* Rating Breakdown */}
                            <Col lg={4} md={12} sm={12} className="border-r border-gray-300">
                                <div className="space-y-1">
                                    {[
                                        { label: 'Excellent', value: 4609 },
                                        { label: 'Very good', value: 1298 },
                                        { label: 'Average', value: 355 },
                                        { label: 'Poor', value: 120 },
                                        { label: 'Terrible', value: 166 },
                                    ].map((item, index) => (
                                        <div key={index} className="flex items-center w-full">
                                            {/* Label */}
                                            <span className="text-gray-900 text-sm font-medium w-[25%]">{item.label}</span>

                                            {/* Progress Bar */}
                                            <div className="w-[55%] h-[12px] bg-gray-200 rounded-full overflow-hidden mx-2 relative top-[10px]">
                                                <div className="h-full yellow-b rounded-full" style={{ width: `${(item.value / 5000) * 100}%` }}></div>
                                            </div>

                                            {/* Count */}
                                            <span className="text-gray-900 text-sm font-medium w-[15%] text-start relative  bottom-[2px]">{item.value}</span>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="pl-[45px]">
                                <div className="grid grid-cols-2 sm:grid-cols-2 gap-y-2 gap-x-4">
                                    {[
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 }
                                    ].map((item, index) => (
                                        <div key={index} className="flex flex-col justify-center items-start leading-[1]">
                                            <div className="leading-[1.4] flex items-center h-[20px]">
                                                <span className="font-medium text-[14px] text-[#000]  pb-[18px] relative bottom-[4px]">{item.label}</span>
                                            </div>
                                            <div className="flex items-center w-full">
                                                <div className="w-[78%] h-[12px] bg-gray-200 rounded-full overflow-hidden mr-2">
                                                    <div className="h-full yellow-b" style={{ width: `${(item.rating / 5) * 100}%` }}></div>
                                                </div>
                                                <div>
                                                    <span className="text-[#000] text-[14px] relative  bottom-[10px]">{item.rating}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            {/* Tags Section */}
                            <Row className="mt-4">
                                <Col lg={12}>
                                    <div className="flex flex-wrap gap-1 mt-2">
                                        {[
                                            { label: '4.5 Innovation & Creativity', starColor: 'green-i' },
                                            { label: '4.5 Innovation & Creativity', starColor: 'yellow-class' },
                                            { label: '4.5 Creativity', starColor: 'yellow-class' },
                                            { label: '4.5 Focus', starColor: 'red-i' }
                                        ].map((item, index) => (
                                            <span
                                                key={index}
                                                className="flex items-center px-2 py-0  font-medium text-black text-sm rounded-full  gap-1 h-m bg-white "
                                            >
                                                <i className={`fas fa-star mr-1 relative bottom-[13px] ${item.starColor}`}></i>
                                                <span className="relative bottom-[13px]">{item.label}</span>
                                                <span className="relative bottom-[13px] text-gray-500">(10)</span>
                                            </span>
                                        ))}
                                    </div>
                                </Col>
                            </Row>


                        </Row>
                    </Container>
                </section>
                <section className="pb-0 pdf-section">
                    <Container>
                        <Row className="bg-white rounded-lg p-6 border-1 ">
                            <Col lg={1} md={2} sm={12}>
                                <img
                                    src={IMAGE_PATHS.BUILDING}
                                    className="w-[60px] h-[60px] bg-gray-300 rounded-md relative top-[10px]"
                                />
                            </Col>
                            <Col lg={10} md={9} sm={12} className="leading-[1.4]">
                                <h4 className="text-lg font-bold text-gray-900 mb-0">Founder & CEO</h4>
                                <p className="text-gray-700 ">Exoways Web Technologies Pvt. Ltd. | Full-time</p>
                                <p className="text-gray-700">Oct 2018 - Present 6 yrs 6 mon</p>
                                <p className="text-gray-700">Chandigarh, India</p>
                                <p className="text-gray-700 mt-2">
                                    Study Abroad Test Prep Platform offering services to B2B & B2C Clients. We have a network of 2000 centres, majorly in India. We have network of centres in Australia, Nepal, Bangladesh, Canada.
                                </p>
                            </Col>

                            <hr className="border-gray-300 my-4 border-t-1" />
                            <Col lg={2} md={12} className="text-start">
                                <h1 className="text-[40px] font-bold text-gray-900 mb-0 leading-[1]">4.5</h1>
                                <p className="text-gray-600 font-semibold">Very Good</p>
                                <div className="yellow-i text-lg">
                                    {[...Array(5)].map((_, index) => (
                                        <i key={index} className="fas fa-star"></i>
                                    ))}
                                </div>
                                <p className="text-gray-500 text-sm">128 Reviews</p>
                            </Col>

                            {/* Rating Breakdown */}
                            <Col lg={4} md={12} sm={12} className="border-r border-gray-300">
                                <div className="space-y-1">
                                    {[
                                        { label: 'Excellent', value: 4609 },
                                        { label: 'Very good', value: 1298 },
                                        { label: 'Average', value: 355 },
                                        { label: 'Poor', value: 120 },
                                        { label: 'Terrible', value: 166 },
                                    ].map((item, index) => (
                                        <div key={index} className="flex items-center w-full">
                                            {/* Label */}
                                            <span className="text-gray-900 text-sm font-medium w-[25%]">{item.label}</span>

                                            {/* Progress Bar */}
                                            <div className="w-[55%] h-[12px] bg-gray-200 rounded-full overflow-hidden mx-2 relative top-[10px]">
                                                <div className="h-full yellow-b rounded-full" style={{ width: `${(item.value / 5000) * 100}%` }}></div>
                                            </div>

                                            {/* Count */}
                                            <span className="text-gray-900 text-sm font-medium w-[15%] text-start">{item.value}</span>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="pl-[45px]">
                                <div className="grid grid-cols-2 sm:grid-cols-2 gap-y-2 gap-x-4">
                                    {[
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 }
                                    ].map((item, index) => (
                                        <div key={index} className="flex flex-col justify-center items-start leading-[1]">
                                            <div className="leading-[1.4] flex items-center h-[20px]">
                                                <span className="font-medium text-[14px] text-[#000] pb-[18px] relative bottom-[4px]">{item.label}</span>
                                            </div>
                                            <div className="flex items-center w-full">
                                                <div className="w-[78%] h-[12px] bg-gray-200 rounded-full overflow-hidden mr-2">
                                                    <div className="h-full yellow-b" style={{ width: `${(item.rating / 5) * 100}%` }}></div>
                                                </div>
                                                <div>
                                                    <span className="text-[#000] text-[14px] relative  bottom-[12px]">{item.rating}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Col>


                            {/* Tags Section */}
                            <Row className="mt-4">
                                <Col lg={12}>
                                    <div className="flex flex-wrap gap-1 mt-2">
                                        {[
                                            { label: '4.5 Innovation & Creativity', starColor: 'green-i' },
                                            { label: '4.5 Innovation & Creativity', starColor: 'yellow-class' },
                                            { label: '4.5 Creativity', starColor: 'yellow-class' },
                                            { label: '4.5 Focus', starColor: 'red-i' }
                                        ].map((item, index) => (
                                            <span
                                                key={index}
                                                className="flex items-center px-2 py-0  font-medium text-black text-sm rounded-full  gap-1 h-m bg-white"
                                            >
                                                <i className={`fas fa-star mr-1 relative bottom-[13px] ${item.starColor}`}></i>
                                                <span className="relative bottom-[13px]">{item.label}</span>
                                                <span className="relative bottom-[13px] text-gray-500">(10)</span>
                                            </span>
                                        ))}
                                    </div>
                                </Col>
                            </Row>


                        </Row>
                    </Container>
                </section>
                <section className=" pt-[20px] pb-[5px] pdf-section ">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <h3 className="text-[28px] font-bold text-black  mb-3">Academic Reviews</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="rounded-lg p-6 pt-0 border-1 bg-1 custm-bg shdo-box" >
                            <h3 className="text-[24px] font-bold mb-0 text-[#000]">Summary</h3>
                            <hr className="border-gray-300 mb-4 border-t-1" />
                            <Col lg={6} md={12} className="mb-6">
                                <div className="flex items-start space-x-3 mb-3">
                                    <div className="mt-[8px]">
                                        <span className="text-[40px] font-bold text-gray-900">4.5</span>
                                    </div>
                                    <div className="leading-[1.4] relative bottom-[8px]">
                                        <span className="text-[16px] font-bold text-gray-900">Very Good</span>
                                        <div className="flex items-center  mb-2">
                                            {[...Array(5)].map((_, index) => (
                                                <i key={index} className="fas fa-star yellow-class text-[16px]"></i>
                                            ))}
                                            <span className="text-gray-600 text-[14px]">128 Reviews</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-[#74787F] text-white  px-4 py-0  rounded-[0.6rem] inline-block shadow-md w-fit text-center ">
                                    <div className="text-[14px] relative bottom-[12px] ">
                                        This summary was created by AI, based on recent reviews.
                                    </div>
                                </div>
                                <p className="text-gray-700 text-[14px] mt-3 leading-[1.5]">
                                    Hyatt Regency Dubai, situated in the heritage heart of the city,
                                    features 421 luxury hotel rooms & suites with great facilities,
                                    recreation center and meeting spaces. Overlooking the Arabian Gulf
                                    and the newly developed Dubai Islands, these spaces offer a calm
                                    atmosphere to work, play and relax.
                                </p>

                                <div className="mt-2">
                                    <h4 className="font-bold text-gray-900 text-[16px] mb-2">
                                        Key Strengths
                                    </h4>
                                    <div className="flex flex-wrap gap-1 relative top-[1px]">
                                        {["Focus", "Innovation", "Teamwork"].map((skill, index) => (
                                            <span
                                                key={index}
                                                className="flex items-center px-2 py-0 text-sm  font-medium text-black rounded-full  gap-1 h-m bg-white"
                                            >
                                                <i key={index} className="fas fa-star green-i relative bottom-[12px]"></i>
                                                <span className="black-class relative bottom-[12px]">4.5 {skill}</span>
                                                <span className="relative bottom-[12px] text-gray-500">(10)</span>
                                            </span>
                                        ))}
                                    </div>
                                </div>

                                 <div className="mt-2">
                                            <h4 className="font-bold text-gray-900 text-[16px] mb-2">
                                                Improvement Areas
                                            </h4>
                                            <div className="flex flex-wrap gap-1 relative top-[1px]">
                                                {["Creativity", "Public Speaking", "Creativity"].map((weakness, index) => (
                                                    <span
                                                        key={index}
                                                        className="flex items-center px-2 py-0  font-medium text-black text-sm rounded-full  gap-1 h-m bg-white"
                                                    >
                                                        <i key={index} className="fas fa-star red-i relative bottom-[12px]"></i>
                                                        <span className="black-class relative bottom-[12px]">1 {weakness}</span>
                                                        <span className="relative bottom-[12px] text-gray-500">(10)</span>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                            </Col>
                            <Col lg={6} md={12} className="">
                                <div className="space-y-3">
                                    {professionalSkills.map((skill, key) => (
                                        <div
                                            key={key}
                                            className="bg-white border rounded-lg p-4 shadow-md"
                                        >
                                            {/* Header Section */}
                                            <div className="flex justify-between items-center mb-4">
                                                {/* Skill Title */}
                                                <span className="font-bold text-gray-800">{skill.title}</span>

                                                {/* Progress Bar & Rating */}
                                                <div className="flex items-center w-[54%]">
                                                    <div className="w-full h-[12px] bg-gray-200 rounded-full overflow-hidden mx-3 relative top-[12px]">
                                                        <div className="h-full yellow-b" style={{ width: `${(skill.rating / 5) * 100}%` }}></div>
                                                    </div>
                                                    <span className="text-gray-800 text-[14px] font-semibold whitespace-nowrap">
                                                        {skill.rating}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap gap-1">
                                                {skill.details.map((detail, idx) => (
                                                    <span
                                                        key={idx}
                                                        className="flex items-center px-2 py-0  font-medium text-black text-sm rounded-full  gap-1 h-m bg-white"
                                                    >
                                                        <i className={`fas fa-star relative bottom-[14px] ${getStarColorClass(skill.rating)} `}></i>
                                                        <span className="text-gray-900 relative bottom-[13px]">{detail}</span>
                                                        <span className="text-gray-500 ml-1 relative bottom-[13px]">(10)</span>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="pdf-section">
                    <Container>
                        {/* Experience Header */}
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <h3 className="text-[22px] font-bold text-gray-900 mb-4  mt-2 leading-[1]">Education</h3>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row className="bg-white rounded-lg p-6 border-1">
                            <Col lg={1} md={2} sm={12}>
                                <img
                                    src={IMAGE_PATHS.SCHOOL}
                                    className="w-[60px] h-[60px] bg-gray-300 rounded-md relative top-[10px]"
                                />
                            </Col>
                            <Col lg={10} md={9} sm={12} className="leading-[1.4]">
                                <h4 className="text-lg font-bold text-gray-900 mb-0">Punjab Technical University</h4>
                                <p className="text-gray-700">Master's Degree, Information Technology</p>
                                <p className="text-gray-700 ">2006 - 2010</p>
                                <p className="text-gray-700">Grade: A</p>
                                <p className="text-gray-700">Master of Science in Information Technology</p>
                                <p className="text-gray-700 mt-2">
                                    Study Abroad Test Prep Platform offering services to B2B & B2C Clients. We have a network of 2000 centres, majorly in India. We have network of centres in Australia, Nepal, Bangladesh, Canada.
                                </p>
                            </Col>

                            <hr className="border-gray-300 my-4 border-t-1" />
                            <Col lg={2} md={12} className="text-start">
                                <h1 className="text-[40px] font-bold text-gray-900 mb-0 leading-[1]">4.5</h1>
                                <p className="text-gray-600 font-semibold">Very Good</p>
                                <div className="yellow-i text-lg">
                                    {[...Array(5)].map((_, index) => (
                                        <i key={index} className="fas fa-star"></i>
                                    ))}
                                </div>
                                <p className="text-gray-500 text-sm">128 Reviews</p>
                            </Col>

                            {/* Rating Breakdown */}
                            <Col lg={4} md={12} sm={12} className="border-r border-gray-300">
                                <div className="space-y-1">
                                    {[
                                        { label: 'Excellent', value: 4609 },
                                        { label: 'Very good', value: 1298 },
                                        { label: 'Average', value: 355 },
                                        { label: 'Poor', value: 120 },
                                        { label: 'Terrible', value: 166 },
                                    ].map((item, index) => (
                                        <div key={index} className="flex items-center w-full">
                                            {/* Label */}
                                            <span className="text-gray-900 text-sm font-medium w-[25%]">{item.label}</span>

                                            {/* Progress Bar */}
                                            <div className="w-[55%] h-[12px] bg-gray-200 rounded-full overflow-hidden mx-2 relative top-[12px]">
                                                <div className="h-full yellow-b rounded-full" style={{ width: `${(item.value / 5000) * 100}%` }}></div>
                                            </div>

                                            {/* Count */}
                                            <span className="text-gray-900 text-sm font-medium w-[15%] text-start ">{item.value}</span>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} className="pl-[45px]">
                                <div className="grid grid-cols-2 sm:grid-cols-2 gap-y-2 gap-x-4">
                                    {[
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 },
                                        { label: 'Value', rating: 4.5 }
                                    ].map((item, index) => (
                                        <div key={index} className="flex flex-col justify-center items-start leading-[1]">
                                            <div className="leading-[1.4] flex items-center h-[20px]">
                                                <span className="font-medium text-[14px] text-[#000] pb-[18px] relative bottom-[4px]">{item.label}</span>
                                            </div>
                                            <div className="flex items-center w-full">
                                                <div className="w-[78%] h-[12px] bg-gray-200 rounded-full overflow-hidden mr-2">
                                                    <div className="h-full yellow-b" style={{ width: `${(item.rating / 5) * 100}%` }}></div>
                                                </div>
                                                <div>
                                                    <span className="text-[#000] text-[14px] relative  bottom-[12px]">{item.rating}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Col>


                            {/* Tags Section */}
                            <Row className="mt-4">
                                <Col lg={12}>
                                    <div className="flex flex-wrap gap-1 mt-2">
                                        {[
                                            { label: '4.5 Innovation & Creativity', starColor: 'green-i' },
                                            { label: '4.5 Innovation & Creativity', starColor: 'yellow-class' },
                                            { label: '4.5 Creativity', starColor: 'yellow-class' },
                                            { label: '4.5 Focus', starColor: 'red-i' }
                                        ].map((item, index) => (
                                            <span
                                                key={index}
                                                className="flex items-center px-2 py-0  font-medium text-black text-sm rounded-full  gap-1 h-m bg-white"
                                            >
                                                <span className="relative bottom-[13px]"><i className={`fas fa-star mr-1 ${item.starColor}`}></i></span>
                                                <span className="relative bottom-[13px]">{item.label}</span>
                                                <span className="relative bottom-[13px] text-gray-500">(10)</span>
                                            </span>
                                        ))}
                                    </div>
                                </Col>
                            </Row>


                        </Row>
                    </Container>
                </section>
                <section className="py-3 pt-0 pdf-section">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between items-baseline">
                                    <div>
                                        <h3 className="text-[22px] font-bold text-gray-900 mb-4 mt-2 leading-[1]">Language</h3>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row className="bg-white rounded-lg p-6 border ">
                            <Col lg={10} md={4} className="mb-2">
                                <h4 className="text-lg font-bold text-gray-900 mb-0">ENGLISH</h4>
                                <p className="font-bold text-gray-900 text-sm">ELTAC CERTIFICATION</p>
                                <p className="text-gray-500 text-sm font-semibold flex items-center">
                                    <span className="mr-2">OCT 2018</span>
                                    <span className="px-2 py-[2px] text-xs font-medium bg-black text-white rounded-full flex items-center relative top-[12px]">
                                        <i className="fas fa-check-circle yellow-class mr-1 relative bottom-[12px]"></i>
                                        <span className="relative bottom-[12px]">Certified</span>
                                    </span>
                                </p>
                            </Col>

                            <hr className="border-gray-300 w-full my-3" />

                            <Col lg={3} md={12} className="text-start">
                                <div className="box-total w-j !pb-[5px]">
                                    <h4 className="text-lg font-bold yellow-class mb-0 text-center relative bottom-[12px]">Total</h4>
                                    <h1 className="text-[40px] font-bold yellow-class leading-[1] mb-0 relative bottom-[3px]">100</h1>
                                </div>

                            </Col>
                            <Col lg={9} md={12} sm={12} className="flex flex-col justify-center">
                                <div className="grid grid-cols-2 md:grid-cols-2 gap-y-2 gap-x-4 w-full">
                                    {[
                                        { label: "Reading", score: 60 },
                                        { label: "Writing", score: 60 },
                                        { label: "Listening", score: 60 },
                                        { label: "Speaking", score: 60 },
                                    ].map((item, index) => (
                                        <div key={index} className="flex items-center justify-between w-full">
                                            {/* Label */}
                                            <span className="text-gray-900 text-sm font-medium w-[25%]">{item.label}</span>

                                            {/* Progress Bar */}
                                            <div className="w-[100%] h-[12px] bg-gray-200 rounded-full overflow-hidden relative top-[13px]">
                                                <div className="h-full yellow-b rounded-full" style={{ width: `${item.score}%` }}></div>
                                            </div>

                                            {/* Score Value */}
                                            <span className="text-gray-900 text-sm font-medium w-[15%] text-end ">{item.score}</span>
                                        </div>
                                    ))}
                                </div>
                            </Col>

                            <hr className="border-gray-300 w-full my-3 border-t-1" />

                            <Col lg={12}>
                                <p className="text-lg font-bold text-gray-900">CEFR Level B1</p>
                                <p className="text-gray-700 text-[14px]">
                                    Can understand a wide range of more demanding, longer texts, and
                                    recognize implicit meaning in them.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className=" pt-0 pb-[30px] pdf-section">
                    <Container>

                        <Row className="bg-white rounded-lg p-6 border ">
                            <Col lg={10} md={4} className="mb-2">
                                <h4 className="text-lg font-bold text-gray-900 mb-0">English</h4>
                                <p className="text-gray-500 text-sm font-semibold flex items-center">
                                    <span className="mr-2">Professional Working Proficiency</span>
                                </p>
                            </Col>

                        </Row>
                    </Container>
                </section>


            </Container>
        </div>


    );
});

export default ProfileReviewPDF;