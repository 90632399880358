import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Dropdown, FormControl, InputGroup } from "react-bootstrap";

import API from '../../Api/Api';
import { IMAGE_PATHS, DOMAIN_URL, formatDate } from '../../Functions/Constant';
import { getStarColorClass, getRatingLabel } from '../../Functions/RatingLabelColor';
import FillterModal from '../../Pages/ComponentPage/FillterModal';
import ConfirmationPopup from '../../Components/ConfirmationPopup'
import StarRating from '../../Components/StarRating'
const ReviewCard = ({ bg }) => {
  //const tooltipRef = useRef([]);
  const tooltipRef = useRef(new Map());
  const [showTooltip, setShowTooltip] = useState(false);
  const [dataPage, setDataPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [popupAction, setPopupAction] = useState(null)
  useEffect(() => {
    fetchFilterData(2);
    const handleClickOutside = (event) => {
      const tooltips = document.querySelectorAll('.report_review_cls');
      let isOutsideAll = true;

      tooltips.forEach((tooltip) => {
        if (tooltip && tooltip.contains(event.target)) {
          isOutsideAll = false;
        }
      });

      if (isOutsideAll) {
        tooltips.forEach((tooltip) => {
          tooltip.classList.remove("opacity-100", "visible");
          tooltip.classList.add("opacity-0", "invisible");
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      tooltipRef.current.clear();
    };
  }, []);

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [language, setLanguage] = useState("all");
  const [sort, setSort] = useState("recent");
  const [hasMore, setHasMore] = useState(true); // Track if more reviews exist
  const [showAllReplies, setShowAllReplies] = useState(false);
  const [filterValData, setFilterValData] = useState({});

  const fetchFilterData = async (type) => {
    const payload = {
      filter_type: type,
    }
    const response = await API.post(`/reviews/filterdata`, payload);
    if (response.filterdata) {
      setFilterValData(response.filterdata);
    }

    console.log(response);
  };

  const [filterData, setFilterData] = useState({
    type: 0,
    sort_by: "",
    ratings: "",
    organization: "",
    professional: "",
    acadaminc: "",
    page: 1,
    popular_mentions: "",
  });
  // Fetch reviews from API
  useEffect(() => {
    setReviews([]);
    const filterDataPayLaod = filterData;
    console.log('filterDataPayLaod',filterDataPayLaod);
    fetchReviews(filterDataPayLaod);
  }, [filterData]);

  const fetchReviews = async (filterData) => {
    //if (!hasMore) return; // Stop fetching if no more reviews
    setLoading(true);

    setError(null);
    try {
      const dataLimit = 5;
      const response = await API.get(`/reviews/allreviews?page=${filterData.page}&limit=${dataLimit}&type=${filterData.type}&sort=${filterData.sort_by}&ratings=${filterData.ratings}&organization=${filterData.organization}&professional=${filterData.professional}&acadaminc=${filterData.acadaminc}&popular_mentions=${filterData.popular_mentions}`);
      if (response.reviews.length === 0) {
        setHasMore(false); // No more data
      } else {
        setDataPage(response.page);
        if (filterData.page <= 1) {
          setReviews(response.reviews);
        } else {
          setReviews(prevReviews => [...prevReviews, ...response.reviews]);
        }
        // Append new data
      }
    } catch (err) {
      setError("Failed to fetch reviews. Try again later.");
    } finally {
      setLoading(false);
    }
  };
  const [focusedReply, setFocusedReply] = useState(false);
  const [replyText, setReplyText] = useState("");
  const toggleReplyBox = (index) => {
    setReviews((prevReviews) =>
      prevReviews.map((review, i) =>
        i === index ? { ...review, is_comment_open: !review.is_comment_open } : review
      )
    );
  };

  const handleReplyChange = (value) => {
    setReplyText(value);
  };
  const addComment = (index, newComment) => {
    setReviews((prevReviews) =>
      prevReviews.map((review, i) =>
        i === index
          ? {
            ...review,
            comment_list: [...review.comment_list, newComment] // Append new comment
          }
          : review
      )
    );
  };
  const addCommentApi = async (index) => {
    const payload = {
      review_id: reviews[index].review_id,
      comment: replyText
    }
    const response = await API.post(`/reviews/addcomment`, payload);
    response.comment.user_name = reviews[index].full_name
    response.comment.user_pic = reviews[index].profile_pic
    response.comment.user_city = reviews[index].location
    response.comment.me = 1;
    addComment(index, response.comment);
    console.log("Response:", response.comment);
    setReplyText("");
  }

  const showReportComment = (idVal) => {
    console.log(idVal);
    const div = document.getElementById(idVal);
    if (div) {
      div.classList.toggle("opacity-100");
      div.classList.toggle("visible");
      div.classList.toggle("opacity-0");
      div.classList.toggle("invisible");
    }
  };
  const showReportReview = (idVal) => {
    const divc = document.getElementById(idVal);
    const divs = document.querySelectorAll('.report_review_cls'); // Select all elements with class
    divs.forEach(div => {
      if (divc != div) {
        div.classList.remove("opacity-100");
        div.classList.remove("visible");
        div.classList.add("opacity-0");
        div.classList.add("invisible");
      }

    });

    if (divc) {
      divc.classList.toggle("opacity-100");
      divc.classList.toggle("visible");
      divc.classList.toggle("opacity-0");
      divc.classList.toggle("invisible");
    }

  };

  

  const reportRespond = async (review_id, comment_id, idVal) => {
    const payload = {
      review_id: review_id,
      comment_id: comment_id
    }
    console.log(review_id, comment_id);
    const response = await API.delete(`/reviews`, payload);
    window.location.reload();
    setShowPopup(false);
    const filterDataPayLaod = filterData;
    fetchReviews(filterDataPayLaod);
  };
  const deleteComment = async (dataArr) => {
    const payload = {
      review_id: dataArr.review_id,
      comment_id: dataArr.comment_id
    }
    //console.log(review_id, comment_id);
    const response = await API.post(`/reviews/deletecomment`, payload);
    //window.location.reload();
    setShowPopup(false);
    const filterDataPayLaod = filterData;
    fetchReviews(filterDataPayLaod);
  }
  // const reportReview = async (review_id, idVal) => {
  //   const payload = {
  //     review_id: review_id
  //   }
  //   const response = await API.post(`/reviews/reportreview`, payload);
  //   showReportReview(idVal);
  // }
  const reportReview = async (dataArr) => {
    const payload = { review_id: dataArr.review_id };
    const response = await API.post(`/reviews/reportreview`, payload);
    setShowPopup(false);
    showReportReview(dataArr.review_id_cls);
  };
  const [showModal, setShowModal] = useState(false);

  const allSkills = Array.from({ length: 50 }, (_, i) => ({
    id: i + 1, // Unique ID for each skill
    name: `Skill ${i + 1}`,
    rating: (Math.random() * 2 + 3).toFixed(1), // Random rating between 3.0 to 5.0
  }));

  const [visibleSkills, setVisibleSkills] = useState(20); // Initially 20 skills
  const [selectedSkills, setSelectedSkills] = useState(new Set()); // Track selected skills

  useEffect(() => {
    //console.log("gdhghdj",selectedSkills);
    let skinl = Array.from(selectedSkills.keys())

    const typeFilterData = { ...filterData, popular_mentions: skinl.join(',') };
    //console.log(typeFilterData);
    setFilterData(typeFilterData);
    //console.log(skinl);
    // setFilterNData(filterData);
    // let 
  }, [selectedSkills]);
  // Toggle Skill Selection
  const handleSkillClick = (skill) => {
    setSelectedSkills((prevSelected) => {
      const newSelection = new Map(prevSelected);
      if (newSelection.has(skill.id)) {
        newSelection.delete(skill.id); // Unselect only this skill
      } else {
        newSelection.set(skill.id, skill.name); // Select this skill
      }
      return newSelection;
    });
  };

  // Load more skills
  const handleLoadMore = () => {
    setVisibleSkills((prev) => prev + 20);
  };
  const filterMethod = (filterData) => {
    console.log('filter', filterData);
    setFilterData(filterData);
  };
  const changeType = (type) => {
    //console.log(filterData,type);
    const typeFilterData = { ...filterData, type: type };
    console.log('type',typeFilterData);
    //console.log(typeFilterData);
    setFilterData(typeFilterData);
  };
  const changeSort = (type) => {
    //console.log(filterData,type);
    const typeFilterData = { ...filterData, sort_by: type };
    //console.log(typeFilterData);
    setFilterData(typeFilterData);
  };

  const getFilterCount = (filterData) => {
    let fcnt = 0;
    if (filterData.ratings != "") {
      fcnt++;
    }
    if (filterData.organization != "") {
      fcnt++;
    }
    if (filterData.professional != "") {
      fcnt++;
    }
    if (filterData.acadaminc != "") {
      fcnt++;
    }
    return fcnt > 0 ? '(' + fcnt + ')' : '';
  }
  return (
    <section className={`${bg} pt-[20px] pb-[50px] px-4`}>
      <Container>
        <Row>
          <Col lg={12} className="p-0">
            {/* Header Section */}
            <h3 className="text-[28px] font-bold text-black mb-0 ">All Reviews ({reviews.length})</h3>
            <p className="text-gray-700 text-[14px] mb-3 leading-[1.5]">
              Reviews are the subjective opinions of users and not of JobCritics. We conduct checks on reviews to maintain trust and authenticity. Learn more in our <a href="/term_policy" className=" underline hover:underline hover:text-black transition" target="_blank" rel="noopener noreferrer"> Terms & Conditions.</a>
            </p>

            <div className="mt-4 flex flex-wrap items-center rounded-lg space-x-3">
              <div className="flex space-x-3">
                {/* Filter Dropdown */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="px-4 py-2 text-sm font-medium border rounded-full bg-white flex items-center"
                    onClick={() => setShowModal(true)}
                  >
                    <i className="fas fa-sliders-h mr-2"></i> Filters {getFilterCount(filterData)}
                    <i className="fas fa-chevron-down ml-2 text-sm"></i>
                  </Dropdown.Toggle>
                </Dropdown>

                {/* Language Dropdown */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="px-4 py-2 text-sm font-medium border rounded-full bg-white flex items-center"
                  >
                    Type <i className="fas fa-chevron-down ml-2 text-sm"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                  <Dropdown.Item onClick={() => changeType(0)} >All</Dropdown.Item>
                  <Dropdown.Item onClick={() => changeType(1)} >Professional</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeType(2)} >Academic</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* Sorting Dropdown */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="px-4 py-2 text-sm font-medium border rounded-full bg-white flex items-center"
                  >
                    Sort by<i className="fas fa-chevron-down ml-2 text-sm"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => changeSort(1)} >Highest Rate</Dropdown.Item>
                    <Dropdown.Item onClick={() => changeSort(2)}>Lowest Rate</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </div>

            {/* Popular Mentions */}
            <div className="mt-3">
              <h3 className="text-sm font-semibold text-gray-900 mb-2">Popular Mentions</h3>

              {/* Skills List */}
              <div className="flex flex-wrap gap-2">
                {filterValData && filterValData.popular_skills && filterValData.popular_skills.length > 0 ? filterValData.popular_skills.map((skill, indexp) => {
                  if (indexp > visibleSkills) {
                    return (<></>);
                  }
                  return (
                    <span
                      key={skill.id}
                      onClick={() => handleSkillClick(skill)}
                      className={`px-2 py-0 rounded-full text-sm font-medium flex items-center h-m h-badge gap-2 cursor-pointer
                 ${selectedSkills.has(skill.id) ? "bg-gray-200 text-black" : "bg-white text-black border"}`
                      }
                    >
                      <i className={`fas fa-star ${getStarColorClass(skill.avg_rating)}`}></i>
                      <span>{skill.name}</span> {/* ID + Name */}
                      <span className="text-gray-500">({skill.total_ratings})</span>
                    </span>
                  )
                }) : (<></>)}

                {/* Load More Button */}
                {/* {filterValData && filterValData.popular_skills && filterValData.popular_skills.length>0 && visibleSkills < filterValData.popular_skills.length && (
                  <button
                    onClick={handleLoadMore}
                    className="text-black font-bold text-sm cursor-pointer hover:bg-gray-200 px-3 py-1 rounded"
                  >
                    Load More
                  </button>
                )} */}
              </div>

            </div>


          </Col>
        </Row>
        {
          reviews.map((review, reviewIndex) => (
            <Row>
              <Col lg={12} className="p-0">
                {/* Review Card */}
                <div className="bg-white border rounded-lg p-5 mt-[15px] mb-2 ">
                  <Row>
                    {/* Left Section: Profile & Rating */}
                    <Col lg={4} md={4} sm={12} className="pr-4">
                      <div >
                        <img
                          src={review.replied_by_pic != null ? DOMAIN_URL + review.replied_by_pic : IMAGE_PATHS.USER}
                          alt={review.replied_by_name}
                          className="w-14 h-14 rounded-full border border-gray-300 object-cover"
                        />
                      </div>
                      <div className="flex items-center space-x-3">
                        <div className="leading-[1.5]">
                          <h2 className="font-medium text-[18px] text-[#000] mb-0 leading-[1.4]">
                            {review.replied_by_name}
                          </h2>
                          <p className="font-medium text-[16px] text-gray-700 ">{review.replied_by_location}</p>
                          {/* <span className="text-xs bg-black text-white px-2 py-1  font-medium">
                            {review.type == 1 ? 'Worked Together' : 'Study Together'}
                          </span> */}
                        </div>
                      </div>

                      {/* Rating */}
                      <div className="flex items-start space-x-3 py-3">
                        {/* Rating Number */}
                        <div className="mt-[8px]">
                          <span className="text-[40px] font-bold text-gray-900 lt">
                            {review.overall_rating}
                          </span>
                        </div>

                        {/* Rating Text & Stars */}
                        <div className="leading-[1.4]">
                          <span className="text-[16px] font-bold text-gray-900">
                            {getRatingLabel(review.overall_rating)}
                          </span>

                          {/* Star Ratings */}
                          <div className="flex items-center  mb-2">
                            {/* {[...Array(5)].map((_, index) => (
                              <i
                                key={index}
                                className={`fas fa-star text-[16px] ${index < Math.round(review.overall_rating)
                                  ? "yellow-class"
                                  : "text-gray-300"
                                  }`}
                              ></i>
                            ))} */}
                            <StarRating rating={review?.overall_rating}  starSize={15}  />
                            {/* <span className="text-gray-600 text-[14px] ml-2">
                              {review.totalReview}
                            </span> */}
                          </div>
                        </div>
                      </div>

                      {/* Rating Progress Bars */}
                      <div >
                        {review.categories ? review.categories.map((item, index) => (
                          <div key={index} className="flex flex-col space-y-0 pr-[80px]">
                            {/* Label */}
                            <span className="text-gray-900 text-sm font-medium leading-[1] pt-[8px]">{item.name}</span>

                            {/* Progress Bar */}
                            <div className="flex items-center space-x-2 h-[19px]">
                              <div className="flex-1 h-[12px] bg-gray-200 rounded-full overflow-hidden relative">
                                <div
                                  className="h-full yellow-b rounded-full"
                                  style={{ width: `${(item.avg_rating / 5) * 100}%` }}
                                ></div>
                              </div>
                              <span className="text-gray-900 text-sm font-medium">{item.avg_rating.toFixed(1)}</span>
                            </div>
                          </div>
                        )) : null}
                      </div>

                    </Col>

                    {/* Right Section: Review Content */}
                    <Col lg={8} md={8} sm={12} className="pr-0">
                      <div className="flex justify-between">
                        <h3 className="text-lg font-bold text-gray-900 mt-2 mb-1">Review by 
                          {review.review_tag === 1 ? " Friend" :
                            review.review_tag === 2 ? " Teacher" :
                              review.review_tag === 3 ? " HR" :
                                review.review_tag === 4 ? " Colleague" :
                                  review.review_tag === 5 ? " Manager" : " Unknown Tag"}
                        </h3>

                        <span className="text-gray-500 text-sm">
                          <span className="m-2">{formatDate(new Date(review.created_at))}</span>
                          <span className="relative">
                            {/* Wrapper with Smooth Hover */}
                            <span className="rond-cls relative inline-block">
                              <i
                                className="fas fa-ellipsis-h cursor-pointer text-gray-700 hover:text-black pt-[7px] pl-[6px]"
                                onClick={() => showReportReview(`c_review${reviewIndex}`)}
                              ></i>
                              <div
                                id={`c_review${reviewIndex}`}
                                ref={el => tooltipRef.current.set(`review-${reviewIndex}`, el)}
                                className={`absolute report_review_cls right-0 mt-2 bg-white shadow-lg border rounded-md px-4 py-2 text-sm w-40 z-10 transition-opacity duration-300 ease-in-out opacity-0 invisible`}
                              >
                                <div className="absolute top-[-8px] right-4 w-4 h-4 bg-white rotate-45 border-l-2 border-t"></div>

                                <button
                                  style={{ textAlign: 'center' }}
                                  className="w-full text-left text-black hover:text-blue-600"
                                  // onClick={() => reportReview(review.review_id, `c_review${reviewIndex}`)}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setSelectedValue({ review_id: review.review_id, review_id_cls: `c_review${reviewIndex}` });
                                    setPopupAction("report");
                                    setShowPopup(true);
                                  }}
                                >
                                  Report review
                                </button>
                              </div>
                            </span>
                          </span>
                        </span>
                      </div>

                      <span className="text-gray-700 text-md font-bold ">
                          
                          {review.type == 1 ? 'Professional Review' : 'Academic Review'}
                        </span>
                      <p className="text-gray-700 text-[14px] mb-3 leading-[1.5]">{review.comments}</p>

                      {/* Tags */}
                      <div className="mt-3 flex flex-wrap gap-2">
                        {review.categories ? review.categories.map((category, index) => (
                          <span
                            key={index}
                            className="flex items-center px-2 py-0 text-sm font-medium text-black rounded-full gap-2 h-m bg-white"
                          >
                            <i className={`fas fa-star mr-1 ${getStarColorClass(category.avg_rating)}`}></i> <span className="black-class"> {category.name}</span>  <span className="text-gray-500" >({category.total_ratings})</span>
                          </span>
                        )) : null}

                      </div>
                      {review.comment_list.length === 0 && (
                        <>
                          <div className="text-gray-700 cursor-pointer hover:underline hover:text-black transition w-fit pt-2" onClick={() => toggleReplyBox(reviewIndex)} >
                            Reply
                          </div>

                          {review.is_comment_open && (
                            <div className="flex items-start w-full">
                              <div className="flex-1 bg-white rounded-lg p-2 transition-all duration-200 border border-black fomc-clsr">
                                <textarea
                                  className="w-full border-none rounded-lg p-2 text-sm focus:outline-none focus:ring-0"
                                  placeholder="Write a reply..."
                                  rows="2"
                                  maxLength={1000}
                                  style={{ resize: "none" }}
                                  value={replyText}
                                  onChange={(e) => handleReplyChange(e.target.value)}
                                ></textarea>

                                <div className="flex items-center justify-end py-0 pr-[2px] pl-[7px]">
                                  <button
                                    onClick={() => addCommentApi(reviewIndex)}
                                    className={`btn-explore-yellow ${!replyText.trim()
                                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                                      : ""
                                      }`}
                                    disabled={!replyText.trim()}
                                  >
                                    Reply
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {/* Reply Section */}
                      {review.comment_list.length > 0 && (
                        <div className="mt-4 p-4 border-l-2 !pr-0">
                          {review.comment_list.slice(0, showAllReplies ? review.comment_list.length : 1).map((item, index) => (
                            <div key={index} className="pb-2">
                              <div className="flex justify-between items-start">
                                {/* User Profile */}
                                <div className="flex items-center space-x-3 leading-[1.4]">
                                  <img
                                    src={item.user_pic != null ? DOMAIN_URL + item.user_pic : IMAGE_PATHS.USER}
                                    alt={item.user_name}
                                    className="w-[40px] h-[40px] rounded-full border border-gray-300 object-cover"
                                  />
                                  <div>
                                    <h4 className="font-medium text-[16px] text-[#000] mb-0 leading-[1]">
                                      {item.user_name}
                                    </h4>
                                    <span className="text-gray-600 text-sm">{item.user_city}</span>
                                  </div>
                                </div>
                                <div className="flex items-baseline">
                                  <p className="text-gray-500 text-sm mr-1"> {formatDate(new Date(item.created_at))}</p>
                                  {/* Three-dot menu with tooltip */}
                                  {item.me == 1 && ( // ✅ Condition added
                                    <div className="relative">
                                      <span className="rond-cls relative inline-block">
                                        <i
                                          className="fas fa-ellipsis-h cursor-pointer text-gray-700 hover:text-black  pl-[5px]"
                                          onClick={() => showReportComment(`c_item${reviewIndex}-${index}`)}
                                        ></i>
                                      </span>

                                      <div
                                        id={`c_item${reviewIndex}-${index}`}
                                        ref={el => tooltipRef.current.set(`comment-${reviewIndex}-${index}`, el)}
                                        className={`absolute report_review_cls right-0 mt-2 bg-white shadow-lg border rounded-md px-4 py-2 text-sm w-max z-10 opacity-0 invisible`}
                                      >
                                        <div className="absolute top-[-8px] right-4 w-4 h-4 bg-white rotate-45 border-l-2 border-t"></div>
                                        <button
                                          className="w-full text-left text-black hover:text-blue-600"
                                          onClick={(event) => {
                                            event.stopPropagation();//review_id, comment_id
                                            setSelectedValue({ review_id: review.review_id, comment_id: item._id, comment_id_cls: `c_item${reviewIndex}-${index}` });
                                            setPopupAction("delete");
                                            setShowPopup(true);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>


                                  )}
                                </div>
                              </div>

                              {/* Reply Content */}

                              <p className="text-gray-700 text-sm mb-3 leading-[1.5] pl-[50px] mt-1">
                                {item.comment}
                              </p>

                              {/* Signature */}
                              {/* <h4 className="text-gray-700 text-sm leading-[1] mb-0 pl-[50px]">
                                {item.user_name}
                              </h4>
                              <span className="text-gray-700 text-sm leading-[1] pl-[50px]">
                                {item.user_city}
                              </span> */}
                            </div>

                          ))}

                          {review.comment_list.length > 5 && (
                            <button
                              className="text-black font-bold text-sm cursor-pointer hover:bg-gray-200 px-2 py-1 rounded pl-[50px]"
                              onClick={() => setShowAllReplies(prev => !prev)}
                            >
                              {showAllReplies ? "Collapse Replies" : "Load More Replies"}
                            </button>
                          )}
                        </div>
                      )}
                    </Col>

                  </Row>
                </div>
              </Col>
            </Row>
          ))
        }
        {!loading && filterData.page < dataPage && (
          <button
            className="text-black text-center w-full py-2 cursor-pointer hover:underline hover:text-black transition !pt-[50px]"
            onClick={() => setPage(prevPage => prevPage + 1)}
          >
            Load more comments
          </button>
        )}

      </Container>
      {showPopup && selectedValue && (
        <ConfirmationPopup
          isOpen={showPopup}
          title={popupAction === "delete" ? "Delete Comment" : "Report Review"}
message={
  popupAction === "delete"
    ? "Are you sure you want to delete this comment? This action cannot be undone."
    : "Are you sure you want to report this review? This action will flag it for review by our team."
}
          onClose={() => setShowPopup(false)} // ✅ Close on cancel
          onConfirm={async () => {
            if (popupAction === "delete") {
              await deleteComment(selectedValue);
              window.location.reload();
            } else if (popupAction === "report") {
              await reportReview(selectedValue);
              window.location.reload();
            }
          }}
          loading={false}
          showCancelButton={true}
        />
      )}
      <FillterModal filtertype="2" filterValData={filterValData} filterData={filterData} filterMethod={filterMethod} show={showModal} handleClose={() => setShowModal(false)} />
    </section>
  );
};



const ReviewPage = ({ bg }) => {
  return (
    <ReviewCard bg={bg} />
  );
};

export default ReviewPage;
