import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const PrivacyModal = ({ show, handleClose, handlePrivacySelect, selectedPrivacy }) => {
    const [privacy, setPrivacy] = useState(selectedPrivacy);

    return (
        <Modal show={show} onHide={handleClose} centered size="md">
            {/* Header */}

            <Modal.Header closeButton className="p-4">
                <Modal.Title className="text-lg font-bold text-gray-900">Post settings</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4 rounded-lg shadow-lg relative bg-white">

                <h3 className="text-lg font-bold text-gray-900 mb-4">Who can see your post?</h3>

                <div className="space-y-4">

                    <div
                        className="flex items-center justify-between p-3 rounded-lg cursor-pointer hover:bg-gray-100"
                        onClick={() => setPrivacy("Anyone")}
                    >
                        <div className="flex items-center">
                            <div className="bg-gray-200 p-3 rounded-full flex items-center justify-center w-12 h-12">
                                <i className="fas fa-globe text-lg text-gray-700"></i>
                            </div>
                            <div className="ml-3">
                                <p className="text-gray-900 font-semibold">Anyone</p>
                                <p className="text-gray-500 text-sm">Anyone on or off JobCritics</p>
                            </div>
                        </div>

                        {privacy === "Anyone" ? (
                            <div className="w-5 h-5 rounded-full border-2 border-black flex items-center justify-center">
                                <div className="w-3 h-3 bg-black rounded-full"></div>
                            </div>
                        ) : (
                            <div className="w-5 h-5 border border-black rounded-full"></div>
                        )}
                    </div>

                    <div
                        className="flex items-center justify-between p-3 rounded-lg cursor-pointer hover:bg-gray-100"
                        onClick={() => setPrivacy("Connections only")}
                    >
                        <div className="flex items-center">
                            <div className="bg-gray-200 p-3 rounded-full flex items-center justify-center w-12 h-12">
                                <i className="fas fa-user-friends text-lg text-gray-700"></i>
                            </div>
                            <div className="ml-3">
                                <p className="text-gray-900 font-semibold">Connections only</p>
                            </div>
                        </div>
                        {/* Selection Indicator */}
                        {privacy === "Connections only" ? (
                            <div className="w-5 h-5 rounded-full border-2 border-black flex items-center justify-center">
                                <div className="w-3 h-3 bg-black rounded-full"></div>
                            </div>
                        ) : (
                            <div className="w-5 h-5 border border-black rounded-full"></div>
                        )}
                    </div>
                </div>

                {/* Done Button */}
                <div className="flex justify-end mt-6">
                    <button
                        className={`px-[16px] rounded-[28px] ${privacy !== selectedPrivacy ? "btn-explore-yellow" : "btn-explore-yellow-disabled !border-gray-300 !border-[1px] disabled:!cursor-not-allowed disabled:!bg-gray-300 disabled:opacity-50 disabled:hover:!border-gray-300 disabled:hover:!bg-gray-300 disabled:!text-white disabled:hover:!text-white"
                            }`}
                        disabled={privacy === selectedPrivacy}
                        onClick={() => {
                            handlePrivacySelect(privacy);
                            handleClose();
                        }}
                    >
                        Done
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PrivacyModal;
