
const HeaderData = (jwtTokenJobcritic) => [
    {
        title: 'Home',
        link: jwtTokenJobcritic ? '/feeds' : '/'
    },
    {
        title: 'For Employer',
        link: '/'
    },
    {
        title: 'For Employee',
        link: '/',
    },
    {
        title: 'Features',
        link: '/'
    }
];

export default HeaderData;
