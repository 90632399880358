import React, { useState, memo, useEffect } from "react";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { m } from "framer-motion";
import PropTypes from "prop-types";
import "../../Assets/scss/components/_accordion.scss";

const RatingSubmit = ({ theme, className, themeColor, data, animation, animationDelay, setRationData }) => {
    const [ratings, setRatings] = useState(
        data.reduce((acc, category) => {
            acc[category.sub_category_id] = category.skills.map(() => 0); // Store actual ratings
            return acc;
        }, {})
    );

    const [hoverRating, setHoverRating] = useState(
        data.reduce((acc, category) => {
            acc[category.sub_category_id] = category.skills.map(() => 0); // Store temporary hover ratings
            return acc;
        }, {})
    );

    // // Handle star rating on click
    // const handleRating = (categoryIndex, skillIndex, rating) => {
    //     setRatings((prevRatings) => {
    //         const newRatings = { ...prevRatings };
    //         newRatings[data[categoryIndex].sub_category_id] = newRatings[data[categoryIndex].sub_category_id] || [];
    //         newRatings[data[categoryIndex].sub_category_id][skillIndex] = rating;
    //         return newRatings;
    //     });
    // };
    // Handle star rating on click (toggle between selected rating and null)
const handleRating = (categoryIndex, skillIndex, rating) => {
    setRatings((prevRatings) => {
        const newRatings = { ...prevRatings };
        newRatings[data[categoryIndex].sub_category_id] = newRatings[data[categoryIndex].sub_category_id] || [];

        // Toggle rating: If the same rating is clicked again, set to null (0)
        newRatings[data[categoryIndex].sub_category_id][skillIndex] =
            newRatings[data[categoryIndex].sub_category_id][skillIndex] === rating ? 0 : rating;

        return newRatings;
    });
};


    // Handle hover effect
    const handleHover = (categoryIndex, skillIndex, rating) => {
        setHoverRating((prevHover) => {
            const newHover = { ...prevHover };
            newHover[data[categoryIndex].sub_category_id] = newHover[data[categoryIndex].sub_category_id] || [];
            newHover[data[categoryIndex].sub_category_id][skillIndex] = rating;
            return newHover;
        });
    };

    // Reset hover effect when mouse leaves
    const handleMouseLeave = (categoryIndex, skillIndex) => {
        setHoverRating((prevHover) => {
            const newHover = { ...prevHover };
            newHover[data[categoryIndex].sub_category_id] = [...newHover[data[categoryIndex].sub_category_id]];
            newHover[data[categoryIndex].sub_category_id][skillIndex] = 0; // Reset to blank when hover is removed
            return newHover;
        });
    };
    useEffect(() => {
        // console.log('data', data);
        // console.log('ratings', ratings);
        // console.log('hoverRating', hoverRating);
        let ratingData = [];
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].skills.length; j++) {
                if (ratings[data[i].sub_category_id] && ratings[data[i].sub_category_id][j]) {
                    ratingData.push({
                        sub_category_id: data[i].sub_category_id,
                        skill_id: data[i].skills[j].skill_id,
                        rating: ratings[data[i].sub_category_id][j]
                    });
                }
                // ratingData.push({
                //     sub_category_id: data[i].sub_category_id,
                //     skill_id: data[i].skills[j].skill_id,
                //     rating: ratings[data[i].sub_category_id]?ratings[data[i].sub_category_id][j]:undefined
                // });
            }
        }
        //console.log('ratingData', ratingData);
        setRationData(ratingData);
    }
        , [data, ratings, hoverRating]);
    return (
        <Accordion className={`${theme} ${themeColor} ${className || ""}`}>

            {/* Custom CSS to remove Bootstrap accordion arrow */}
            <style>{`
                .accordion-button::after {
                   background-image: none !important;
                }
            `}</style>

            {data.map((category, categoryIndex) => (
                <m.div
                    key={categoryIndex}
                    className="accordion-item-wrapper bor-q"
                    {...{ ...animation, transition: { delay: categoryIndex * animationDelay } }}>
                    <Accordion.Item eventKey={categoryIndex.toString()} >
                        {/* Category Title */}
                        <Accordion.Header className="text-lg font-bold text-black">
                            {category.title}
                        </Accordion.Header>

                        {/* Sub-skills inside the expanded section */}
                        <Accordion.Body >
                            <div className="flex flex-wrap gap-3">
                                {category.skills.map((skill, skillIndex) => (
                                    <div key={skillIndex} className="flex items-center space-x-2">

                                        {/* Skill Name with Tooltip */}

                                        <span className="flex items-center px-2 py-1 rounded-full text-xs font-medium text-black border border-gray-300 bg-white">
                                            {skill.name} <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip className="custom-tooltip">
                                                    {skill.description}
                                                </Tooltip>
                                            }
                                            delay={{ show: 200, hide: 200 }}
                                        ><i className=" fas fa-info-circle text-gray-500 font-bold text-[12px] cursor-pointer ml-[5px]" ></i>
                                        </OverlayTrigger>
                                        </span>

                                        

                                        {/* Star Ratings */}
                                        <div className="flex items-center">
                                            {[...Array(5)].map((_, starIndex) => {
                                                const ratingLabels = ["Terrible", "Poor", "Average", "Very Good", "Excellent"];

                                                return (
                                                    <OverlayTrigger
                                                        key={starIndex}
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">{ratingLabels[starIndex]}</Tooltip>}
                                                        delay={{ show: 200, hide: 200 }}
                                                    >
                                                        <i
                                                            className={`fas fa-star transition-all duration-300 cursor-pointer ${starIndex < ((hoverRating[category.sub_category_id] && hoverRating[category.sub_category_id][skillIndex]) || (ratings[category.sub_category_id] && ratings[category.sub_category_id][skillIndex]))
                                                                ? "yellow-class"
                                                                : "text-gray-300"
                                                                }`}
                                                            onMouseEnter={() => handleHover(categoryIndex, skillIndex, starIndex + 1)}
                                                            onMouseLeave={() => handleMouseLeave(categoryIndex, skillIndex)}
                                                            onClick={() => handleRating(categoryIndex, skillIndex, starIndex + 1)}
                                                        />
                                                    </OverlayTrigger>
                                                );
                                            })}
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </m.div>
            ))}
        </Accordion>
    );
};

// Default Props
RatingSubmit.defaultProps = {
    data: [],
    animationDelay: 0.2,
    theme: "accordion-style-01",
    themeColor: "light",
};

// Prop Types Validation
RatingSubmit.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            skills: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ),
    animation: PropTypes.object,
    animationDelay: PropTypes.number,
    theme: PropTypes.string,
    themeColor: PropTypes.string,
};

export default memo(RatingSubmit);
