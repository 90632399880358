import React, { useEffect, useState } from "react";
import { IMAGE_PATHS } from "../Functions/Constant";
import Loader from "./Loader";

const ConfirmationPopup = ({ isOpen, title, message, onClose, onConfirm, loading, showCancelButton = true }) => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";

      setShowLoader(true);
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
        document.documentElement.style.overflow = "auto";
        document.body.style.overflow = "auto";
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-d">
      {showLoader ? (
       <Loader/>
      ) : (
        <div className="bg-white p-6 rounded-2xl shadow-xl w-[520px] relative flex flex-col ">
          {/* Close Button */}
          <button
            className="absolute top-2 right-3 w-[2.0rem] h-[2.0rem] text-black rounded-full hover:bg-gray-200 transition-all"
            onClick={onClose}
            disabled={loading}
          >
           <svg
  className="svg-icon"
  style={{
    height: "28px",
    verticalAlign: "middle",
    fill: "currentColor",
    overflow: "hidden",
  }}
  viewBox="0 0 1024 1024"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M704.28672 309.20704l28.95872 28.9792L334.6432 736.78848l-28.95872-28.9792z" fill="#231815" />
  <path d="M341.03296 315.5968l398.60224 398.60224-28.95872 28.95872-398.60224-398.60224z" fill="#231815" />
</svg>
          </button>

          {/* Title */}
          <h6 className=" font-semibold text-black text-left w-full mb-3">{title}</h6>
          <p className="text-gray-600 ">{message}</p>

          {/* Buttons */}
          <div className="flex mt-6 w-full">
            {showCancelButton ? (
              <>
                <button className="btn-explore-white mr-[12px] rs" onClick={onClose} disabled={loading}>
                  Cancel
                </button>
                <button className="btn-explore-yellow rs" onClick={onConfirm} disabled={loading}>
                  Confirm
                </button>
              </>
            ) : (
              <button className="btn-explore-yellow rs" onClick={onConfirm} disabled={loading}>
                OK
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmationPopup;
