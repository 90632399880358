// ThemeDesign.jsx
import React from 'react';
import Typography from '../Components/Typography'; // Adjust the import path as needed
import StarRating from '../Components/StarRating'
import { Container, Row, Col } from 'react-bootstrap';

const ThemeDesign = () => {
  return (<div className="p-10 bg-gray-100">
    <div className="max-w-2xl mx-auto bg-white p-6 rounded-xl shadow-md space-y-6">
      <h1 className="text-5xl font-heading font-bold leading-tight">H1: Welcome to Job Critics</h1>
      <h2 className="text-4xl font-heading font-semibold leading-snug">H2: Find Jobs That Match Your Skills</h2>
      <h3 className="text-2xl font-heading font-semibold leading-snug">H3: Featured Categories</h3>
      <h4 className="text-xl font-heading font-semibold leading-normal">H4: Why Choose Job Critics?</h4>
      <h5 className="text-lg font-heading font-medium leading-normal">H5: Quick Highlights</h5>
      <h6 className="text-base font-heading font-medium leading-relaxed">H6: *Start your journey today*</h6>

      <p className="text-base font-body text-secondary leading-loose">
        Body Text: At Job Critics, we believe that finding a job shouldn’t be a guessing game. Our platform aggregates real employee reviews, company ratings, and job openings to help you make smarter career choices.
      </p>

      <p className="text-sm font-body text-secondary">Small Text: Used for metadata & captions</p>
      <p className="text-xs font-body text-muted">Caption: *All reviews are verified by real employees.*</p>

      <div className="flex space-x-4">
        <button className="text-sm font-heading font-semibold uppercase tracking-wider text-white bg-primary py-3 px-6 rounded-xl shadow-md">
          Apply Now
        </button>
        <button className="text-sm font-heading font-medium text-primary border border-primary py-2 px-5 rounded-xl">
          See Details
        </button>
      </div>

      <input
        type="text"
        placeholder="Search for Jobs..."
        className="text-sm font-body border border-gray-300 rounded-md p-3 w-72"
      />

      <span className="text-xs font-heading font-medium text-white bg-primary py-1 px-3 rounded">
        Top Rated
      </span>
    </div>
  </div>
  );
};

export default ThemeDesign;