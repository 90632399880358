import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Navbar, Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header, { HeaderNav, Menu } from '../../Components/Header/Header';
import { IMAGE_PATHS,DOMAIN_URL } from '../../Functions/Constant';
import API from '../../Api/Api';

const MyHeader = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [userData, setUserData] = useState(null);
  const [jwtTokenJobcritic, setjwtTokenJobcritic] = useState(localStorage.getItem("jwt_token_jobcritic"));
  const navigate = useNavigate();

  const fetchDataUser = async () => {
    try {
        const endpoint = `/user`;
        const res = await API.get(endpoint);
        if (res.user) {
            const userData = {
                name: res.user.name || "Unknown User",
                profile_image: res.user.profile_image ? DOMAIN_URL + res.user.profile_image : IMAGE_PATHS.USER
                
            };

            setUserData(userData);
            localStorage.setItem("userData", JSON.stringify(userData));
        }
    } catch (error) {
        console.error(error);
    } finally {
    }
};
  useEffect(() => {
    // document.querySelector("body").classList.remove("overflow-hidden");
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      try {
        setUserData(JSON.parse(storedUserData)); 
      } catch (error) {
        console.error("Error parsing userData:", error);
        fetchDataUser(); 
      }
    } else {
      fetchDataUser(); 
    }

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 27);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle navigation for review button
  const handleReviewClick = () => {
    if (jwtTokenJobcritic) {
      navigate("/write_review");
    } else {
      navigate("/login");
    }
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem("jwt_token_jobcritic");
    localStorage.removeItem("userData");
    setjwtTokenJobcritic(null);
    setUserData(null);
    window.location.href = "/";
  };


 

  return (
    <Header topSpace={{ md: true }} type="reverse-scroll">
      <HeaderNav theme="light" expand="lg" className="py-[0px] px-[35px] lg:px-[15px] md:px-[15px] md:py-[20px] sm:px-0">
        
        {/* Logo */}
        <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
          <Link aria-label="header logo" className="flex items-center" to="/">
            <Navbar.Brand className="inline-block p-0 m-0">
              <img className="default-logo" width="111" height="60" src={IMAGE_PATHS.LOGO} alt="logo" />
              <img className="alt-logo" width="111" height="60" src={IMAGE_PATHS.LOGO} alt="logo" />
            </Navbar.Brand>
          </Link>
        </Col>

        {/* Navbar Toggle */}
        <Navbar.Toggle className="order-last md:ml-[17px] md:px-[15px] sm:px-0">
          <span className="navbar-toggler-line"></span>
          <span className="navbar-toggler-line"></span>
          <span className="navbar-toggler-line"></span>
          <span className="navbar-toggler-line"></span>
        </Navbar.Toggle>

        {/* Navigation Menu */}
        <Navbar.Collapse className="justify-center col-auto col-lg-8">
          <Menu {...props} />
        </Navbar.Collapse>

        {/* Buttons and User Avatar */}
        <Col lg={2} xs={"auto"} className="justify-end pe-0 flex items-center">
          
          {/* Write Review Button */}
          <Button className="btn-explore-yellow my-3" onClick={handleReviewClick}>
            Write Review
          </Button>

          {/* User Login / Profile Dropdown */}
          {jwtTokenJobcritic ? (
            <Dropdown className="ml-[1.3rem]">
              <Dropdown.Toggle as="div" className="cursor-pointer">
                <img
                  src={userData?.profile_image || IMAGE_PATHS.USER }
                  alt="User"
                  className="w-[40px] h-[40px] rounded-full object-cover border border-gray-300"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="bg-white shadow-lg rounded-lg py-2 w-auto">
              <Dropdown.Item as={Link} to="/employee_detail" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                  Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/profile" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                  Settings
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout} className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <span className="ml-[1.3rem]">
              <Link to="/login" className="font-bold text-lg text-black transition duration-300 log-cl">
                Login
              </Link>
            </span>
          )}
        </Col>
      </HeaderNav>
    </Header>
  );
};

export default MyHeader;
