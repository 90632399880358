import React, { useEffect, useState } from "react";
import { IMAGE_PATHS } from "../Functions/Constant";

const Loader = () => {


  return (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <img src={IMAGE_PATHS.LOADER} alt="Loading..." className="w-12 h-12" />
        </div>
  )}
     

export default Loader;
