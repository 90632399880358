import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import MainHeader from "../Pages/Header/MainHeader";
import FooterStyle from '../Pages/Footer/Footer';
import StickTab from '../Components/StickTab/StickTab';
import EmployeeReviwe from './ComponentPage/EmployeeReviwe';
import AboutSection from './ComponentPage/AboutSection';
import Academic from './ComponentPage/Acedmic';
import AllReview from './ComponentPage/AllReview';
import StarRating from '../Components/StarRating'
import { Link } from 'react-router-dom';
    import API from '../Api/Api';
import { IMAGE_PATHS, DOMAIN_URL } from '../Functions/Constant';


const DefaultTabMenu = [
    { tabTitle: 'Overview', refKey: 'overviewRef' },
];

const TabMenu = [
    { tabTitle: 'Overview', refKey: 'overviewRef' },
    { tabTitle: 'Professional Reviews', refKey: 'reviewsRef' },
    { tabTitle: 'Academic Reviews', refKey: 'academicRef' },
    { tabTitle: 'All Reviews', refKey: 'allReviewsRef' }
];


const EmployeeDetail = (props) => {
    const [isSticky, setSticky] = useState(false);
    const [stickyHeight, setStickyHeight] = useState(0);
    const stickyRef = useRef(null);
    const [userData, setUserData] = useState({
        name: null,
        profile_image: null, // Default placeholder
    });
    const [ratings, setRatings] = useState({
        overall_rating: 0,
        total_reviews: null,
        is_ai_summary: 0,
        excellent: 0,
        very_good: 0,
        average: 0,
        poor: 0,
        terrible: 0
    });
    const [mainRating, setMainRating] = useState({
        core: 0,
        creativity: 0,
        personal: 0,
        technical: 0,
        interpersonal: 0
    });
    const [professional, setProfessional] = useState({
        ai_summary: "No AI summary available",
        key_strengths: [],
        improvement_areas: [],
        category_ratings: null,
    });
    const [ratingsPorfin, setRatingsPorfin] = useState();
    const [academic, setAcademic] = useState();
    const [academicRatings,setAcademicRatings] = useState();


    // Define section references for smooth scrolling
    const overviewRef = useRef(null);
    const reviewsRef = useRef(null);
    const academicRef = useRef(null);
    const allReviewsRef = useRef(null);

    const fetchData = async () => {
        try {
            const endpoint = `/reviews/overall_rating`;
            const res = await API.get(endpoint);

            if (res.overall_rating) {
                setRatings({
                    overall_rating: res.overall_rating.overall_rating || 0,
                    total_reviews: res.overall_rating.total_reviews || 0,
                    is_ai_summary: res.overall_rating.is_ai_summary || 0,
                    excellent: res.overall_rating.excellent || 0,
                    very_good: res.overall_rating.very_good || 0,
                    average: res.overall_rating.average || 0,
                    poor: res.overall_rating.poor || 0,
                    terrible: res.overall_rating.terrible || 0

                });
            }
            if (res.category_ratings) {
                setMainRating({
                    core: res.category_ratings.core || 0,
                    creativity: res.category_ratings.creativity || 0,
                    personal: res.category_ratings.personal || 0,
                    technical: res.category_ratings.technical || 0,
                    interpersonal: res.category_ratings.interpersonal || 0
                })

            }
            if(res.professional){
                //console.log(res.professional.overall_rating);
                setProfessional({
                    ai_summary: res.professional.ai_summary || "No AI summary available",
                    key_strengths: res.professional.key_strengths,
                    improvement_areas: res.professional.improvement_areas,
                    category_ratings: res.professional.category_ratings,
                });
                if (res.professional.overall_rating) {
                    setRatingsPorfin({
                        overall_rating: res.professional.overall_rating ?? 0,
                        total_reviews: res.professional.total_reviews ?? 0,
                        excellent: res.professional.excellent ?? 0,
                        very_good: res.professional.very_good ?? 0,
                        average: res.professional.average ?? 0,
                        poor: res.professional.poor ?? 0,
                        terrible: res.professional.terrible ?? 0
                    });
                }
            }
            if (res.academic) {
                setAcademic({
                    ai_summary: res.academic.ai_summary || "No AI summary available",
                    key_strengths: res.academic.key_strengths,
                    improvement_areas: res.academic.improvement_areas ,
                    category_ratings:  res.academic.category_ratings,
                });
                if (res.academic.overall_rating) {
                    setAcademicRatings({
                        overall_rating: res.academic.overall_rating ?? 0,
                        total_reviews: res.academic.total_reviews ?? 0,
                        excellent: res.academic.excellent ?? 0,
                        very_good: res.academic.very_good ?? 0,
                        average: res.academic.average ?? 0,
                        poor: res.academic.poor ?? 0,
                        terrible: res.academic.terrible ?? 0
                    });
                }
            }

            
            console.log(res)
        } catch (error) {
            console.error(error);
        } finally {
        }
    };
    const fetchDataUser = async () => {
        try {
            const endpoint = `/user`;
            const res = await API.get(endpoint);
            if (res.user) {
                const userData = {
                    name: res.user.name,
                    profile_image: DOMAIN_URL+res.user.profile_image || IMAGE_PATHS.USER,
                };

                setUserData(userData);
                localStorage.setItem("userData", JSON.stringify(userData));
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    };
    const fetchDataProfessional = async () => {
        try {
            const res = await API.get(`/reviews/professional`);
            if (res?.professional) {
                setProfessional({
                    ai_summary: res.professional.ai_summary || "No AI summary available",
                    key_strengths: Array.isArray(res.professional.key_strengths) ? res.professional.key_strengths : [],
                    improvement_areas: Array.isArray(res.professional.improvement_areas) ? res.professional.improvement_areas : [],
                    category_ratings: res.professional.category_ratings || {},
                });
            }
            if (res?.overall_rating) {
                setRatingsPorfin({
                    overall_rating: res.overall_rating.overall_rating ?? 0,
                    total_reviews: res.overall_rating.total_reviews ?? 0,
                    excellent: res.overall_rating.excellent ?? 0,
                    very_good: res.overall_rating.very_good ?? 0,
                    average: res.overall_rating.average ?? 0,
                    poor: res.overall_rating.poor ?? 0,
                    terrible: res.overall_rating.terrible ?? 0
                });
            }
            const resExp = await API.get(`/experiences`);
            console.log(resExp);
        } catch (error) {
            console.error("Error fetching professional reviews:", error);
        }
    };
    const fetchDataAcademic = async () => {
        try {
            const res = await API.get(`/reviews/academic`);

            if (res?.academic) {
                setAcademic({
                    ai_summary: res.academic.ai_summary || "No AI summary available",
                    key_strengths: Array.isArray(res.academic.key_strengths) ? res.academic.key_strengths : [],
                    improvement_areas: Array.isArray(res.academic.improvement_areas) ? res.academic.improvement_areas : [],
                    category_ratings: res.academic.category_ratings || {},
                });
            }

            if (res?.academic?.overall_rating) {
                setAcademicRatings({
                    overall_rating: res.academic.overall_rating.overall_rating ?? 0,
                    total_reviews: res.academic.overall_rating.total_reviews ?? 0,
                    excellent: res.academic.overall_rating.excellent ?? 0,
                    very_good: res.academic.overall_rating.very_good ?? 0,
                    average: res.academic.overall_rating.average ?? 0,
                    poor: res.academic.overall_rating.poor ?? 0,
                    terrible: res.academic.overall_rating.terrible ?? 0
                });
            }
        } catch (error) {
            console.error("Error fetching academic reviews:", error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataUser();
        //fetchDataProfessional();
        //fetchDataAcademic();
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const stickyThreshold = window.innerWidth < 992 ? 225 : 205;
            setSticky(window.scrollY >= stickyThreshold);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Measure Sticky Section Height to prevent layout shift
    useEffect(() => {
        if (stickyRef.current) {
            setStickyHeight(stickyRef.current.clientHeight);
        }
    }, []);
    const scrollToAllReviews = (type) => {
        console.log('typeeee', type)
        const offset = 40; // Adjust this value as needed
        if (allReviewsRef.current) {
            const elementPosition = allReviewsRef.current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <div style={props.style}>
            <MainHeader />

            {/* Normal Section */}
            <section className="pt-[120px] overflow-hidden sm:py-[50px] ">
                <Container>
                    <Row className="items-end justify-between">
                        <Col lg={12} md={12} sm={12}>
                            <div className="flex items-end space-x-6 mb-3">


                              { userData.name!=null?(
                                <Link to="/profile" className="cursor-pointer">
                                <img
                                  src={userData.profile_image}
                                  alt="User"
                                  className="w-[80px] h-[80px] rounded-full object-cover border border-gray-300"
                              />
                              </Link>
                              ):(
                              <div class="animate-pulse rounded-full bg-gray-300 w-[80px] h-[80px]"></div>
                              ) }


                                <div className="flex-1">
                                    { userData.name!=null ?
                                      (<h1 className="text-[28px] font-bold text-black mb-0 leading-tight">{userData.name}</h1>):(<div class="animate-pulse h-10 bg-gray-300 rounded w-[180px] mb-3"></div>)
                                    }

                                    {
                                      ratings.total_reviews!=null?(
                                        <div className="flex items-center ">
                                            
                                        <>
                                        <StarRating rating={ratings.overall_rating}  starSize={15}  />
                                            {/* {[...Array(5)].map((_, index) => (
                                                <span key={index} className={` ${index < ratings.overall_rating ? "yellow-class" : "text-gray-300"}`}>
                                                    <i className="fas fa-star"></i>
                                                </span>
                                            ))} */}
                                            <span className={`text-gray-600 text-[14px] ml-2 pt-[2px]  ${ratings.total_reviews > 0 ? `cursor-pointer hover:underline hover:text-black transition` : ""}`} onClick={scrollToAllReviews}>{ratings.total_reviews > 0 ? `${ratings.total_reviews} Reviews` : "No Reviews Yet"}</span>
                                        </>
                                            {ratings.is_ai_summary === 1 && (
                                                <span className="px-2 py-[2px] text-sm font-medium yellow-b rounded-md black-class">
                                                    <span className="text-lg text-black">   <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        width="16"
                                                        height="16"
                                                        className="inline-block mr-1"
                                                    >
                                                        <path
                                                            d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg></span> AI Reviews Summary
                                                </span>
                                            )}

                                        </div>
                                      ):(<div class="animate-pulse h-5 bg-gray-300 rounded w-[150px] h-[12px]"></div>)
                                    }


                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div style={{ height: isSticky ? `${stickyHeight}px` : 'auto' }}>
                    <div
                        ref={stickyRef}
                        className={`w-full z-[50] transition-all duration-500 ease-in-out ${isSticky ? 'animated-box' : 'border-b border-t border-solid border-mediumgray'}`}
                    >
                        <Container >
                            <StickTab
                                data={ ratings.total_reviews>0 ? TabMenu: DefaultTabMenu }
                                sectionRefs={{ overviewRef, reviewsRef, academicRef, allReviewsRef }}
                            // isSticky={isSticky} // Pass the sticky state
                            />
                        </Container>
                    </div>
                </div>
            </section>

            {/* Sections with Refs */}
            <div ref={overviewRef}>
                <EmployeeReviwe ratings={ratings} mainRating={mainRating} scrollToReviews={scrollToAllReviews} type="all"/>
            </div>

            <div ref={reviewsRef}>
                <AboutSection ratings={ratings} professional={professional} ratingsPorfin={ratingsPorfin} scrollToReviews={scrollToAllReviews} type="professional" />
            </div>
                <div ref={academicRef}>
                    <Academic scrollToReviews={scrollToAllReviews} academic={academic} academicRatings={academicRatings} type="academic"/>
                </div>
            {
              ratings.total_reviews>0 && (<div ref={allReviewsRef}>
                  <AllReview bg="custm-bg" />
              </div>)
            }


            <FooterStyle theme="light" className="bg-black text-[#828282] seo-agency-footer_06" />
        </div>
    );
};

export default EmployeeDetail;
