import React, { useState, useRef, useEffect} from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import './EditPhoto.css';

const EditPhoto = ({ photo, onSave, onClose }) => {
  const [tab, setTab] = useState('Crop'); // Active tab: Crop, Filter, Adjust
  const [scale, setScale] = useState(1.5); // Zoom for cropping
  const [rotation, setRotation] = useState(0); // Rotation for cropping
  const [brightness, setBrightness] = useState(100); // Brightness (0-200)
  const [contrast, setContrast] = useState(100); // Contrast (0-200)
  const [saturation, setSaturation] = useState(100); // Saturation (0-200)
  const [vignette, setVignette] = useState(0); // Vignette (0-100)
  const [filter, setFilter] = useState('Original'); // Selected filter
  const editorRef = useRef(null);
  const editorContainerRef = useRef(null);

  // Predefined filters (simulating the ones in the screenshot)
  const filters = [
    { name: 'Original', filter: '' },
    { name: 'Studio', filter: 'contrast(1.2) brightness(1.1)' },
    { name: 'Spotlight', filter: 'brightness(1.3) contrast(1.1) saturate(1.2)' },
    { name: 'Prime', filter: 'saturate(1.5) brightness(0.9)' },
    { name: 'Classic', filter: 'sepia(0.5) contrast(1.1)' },
    { name: 'Edge', filter: 'grayscale(1) contrast(1.5)' },
    { name: 'Luminary', filter: 'brightness(1.4) saturate(1.3)' },
  ];

  // Handle saving the edited photo
  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      //console.log(canvas);
      const editedPhoto = canvas.toDataURL('image/png');
      //console.log(editedPhoto);
      onSave(editedPhoto); // Pass the edited photo back to the parent component
    }
  };

  // Apply CSS filters based on adjustments and selected filter
  const getFilterStyle = () => {
    const selectedFilter = filters.find(f => f.name === filter)?.filter || '';
    return `
      ${selectedFilter}
      brightness(${brightness}%)
      contrast(${contrast}%)
      saturate(${saturation}%)
    `;
  };
// Add mouse wheel event listener for zoom
useEffect(() => {
  const handleWheel = (e) => {
    e.preventDefault(); // Prevent page scrolling
    const zoomSpeed = 0.1; // Adjust zoom speed
    const delta = e.deltaY > 0 ? -zoomSpeed : zoomSpeed; // Scroll down to zoom out, up to zoom in
    setScale((prevScale) => {
      const newScale = Math.max(1, Math.min(3, prevScale + delta)); // Limit scale between 1 and 3
      return newScale;
    });
  };

  const editorContainer = editorContainerRef.current;
  if (editorContainer) {
    editorContainer.addEventListener('wheel', handleWheel, { passive: false });
  }

  return () => {
    if (editorContainer) {
      editorContainer.removeEventListener('wheel', handleWheel);
    }
  };
}, []);
  return (
    <>
    <div className="edit-content">
        <div className="image-editor" ref={editorContainerRef}>
          {photo && (
            <ReactAvatarEditor
              ref={editorRef}
              image={photo}
              width={250}
              height={250}
              border={50}
              borderRadius={125} // Circular crop
              scale={scale}
              rotate={rotation}
              style={{ filter: getFilterStyle() }}
            />
          )}
        </div>

        <div className="edit-controls">
          {/* <div className="tabs">
            <button
              className={tab === 'Crop' ? 'active' : ''}
              onClick={() => setTab('Crop')}
            >
              Crop
            </button>
            <button
              className={tab === 'Filter' ? 'active' : ''}
              onClick={() => setTab('Filter')}
            >
              Filter
            </button>
            <button
              className={tab === 'Adjust' ? 'active' : ''}
              onClick={() => setTab('Adjust')}
            >
              Adjust
            </button>
          </div> */}

          {tab === 'Crop' && (
            <div className="crop-controls">
              <div className="control">
                <label>Zoom</label>
                <input
                  type="range"
                  min="1"
                  max="3"
                  step="0.1"
                  value={scale}
                  onChange={(e) => setScale(parseFloat(e.target.value))}
                />
              </div>
              <div className="control">
                <label>Straighten</label>
                <input
                  type="range"
                  min="-45"
                  max="45"
                  value={rotation}
                  onChange={(e) => setRotation(parseInt(e.target.value))}
                />
              </div>
            </div>
          )}

          {tab === 'Filter' && (
            <div className="filter-controls">
              {filters.map((f) => (
                <div
                  key={f.name}
                  className={`filter-option ${filter === f.name ? 'selected' : ''}`}
                  onClick={() => setFilter(f.name)}
                >
                  <div
                    className="filter-preview"
                    style={{
                      backgroundImage: `url(${photo})`,
                      filter: f.filter,
                    }}
                  />
                  <span>{f.name}</span>
                </div>
              ))}
            </div>
          )}

          {tab === 'Adjust' && (
            <div className="adjust-controls">
              <div className="control">
                <label>Brightness</label>
                <input
                  type="range"
                  min="0"
                  max="200"
                  value={brightness}
                  onChange={(e) => setBrightness(parseInt(e.target.value))}
                />
              </div>
              <div className="control">
                <label>Contrast</label>
                <input
                  type="range"
                  min="0"
                  max="200"
                  value={contrast}
                  onChange={(e) => setContrast(parseInt(e.target.value))}
                />
              </div>
              <div className="control">
                <label>Saturation</label>
                <input
                  type="range"
                  min="0"
                  max="200"
                  value={saturation}
                  onChange={(e) => setSaturation(parseInt(e.target.value))}
                />
              </div>
              <div className="control">
                <label>Vignette</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={vignette}
                  onChange={(e) => setVignette(parseInt(e.target.value))}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="modal-footer">
        <button className="px-6 py-2 btn-explore-yellow" onClick={handleSave}>
          Save photo
        </button>
          

      </div>
    </>
    
  );
};

export default EditPhoto;