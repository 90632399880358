import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { IMAGE_PATHS, DOMAIN_URL } from '../../Functions/Constant';


const VoteModal = ({ selectedVotes, closeVoteModal }) => {
    const [visibleUsers, setVisibleUsers] = useState([]);
    const listRef = useRef(null);

    useEffect(() => {
        if (selectedVotes) {
            setVisibleUsers(selectedVotes.users.slice(0, 10)); // Load 10 users initially
        }
    }, [selectedVotes]);

    const loadMoreUsers = () => {
        if (!selectedVotes) return;
        const nextUsers = selectedVotes.users.slice(visibleUsers.length, visibleUsers.length + 10);
        setVisibleUsers((prev) => [...prev, ...nextUsers]);
    };

    const handleScroll = () => {
        if (listRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                loadMoreUsers();
            }
        }
    };

    return (
        <Modal show={!!selectedVotes} onHide={closeVoteModal} centered>
            <Modal.Body className="p-4 rounded-lg shadow-lg relative bg-white">
                {/* Header */}
                <div className="flex justify-between items-center">
                    <h3 className="text-lg font-bold mb-0">
                        {selectedVotes?.type === "1" ? "People who upvoted" : "People who downvoted"}
                    </h3>
                    {/* <button
                        className="text-gray-500 hover:text-black text-lg"
                        onClick={closeVoteModal}
                    >
                        <i className="fas fa-times"></i> 
                    </button> */}
                    <button
                        className="absolute top-4 right-3 w-[2.0rem] h-[2.0rem] text-black rounded-full hover:bg-gray-200 transition-all"
                        onClick={closeVoteModal}
                    >
                        <svg
                            className="svg-icon"
                            style={{
                                height: "28px",
                                verticalAlign: "middle",
                                fill: "currentColor",
                                overflow: "hidden",
                            }}
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M704.28672 309.20704l28.95872 28.9792L334.6432 736.78848l-28.95872-28.9792z" fill="#231815" />
                            <path d="M341.03296 315.5968l398.60224 398.60224-28.95872 28.95872-398.60224-398.60224z" fill="#231815" />
                        </svg>
                    </button>
                </div>

                {/* Filters */}
                <div className="flex space-x-6 border-b py-2 mt-2">
                    <span className="text-blue-600 font-semibold cursor-pointer">All {visibleUsers.length}</span>
                </div>

                {/* Scrollable List */}
                <div className="max-h-96 overflow-y-auto" ref={listRef} onScroll={handleScroll}>
                    {visibleUsers.length > 0 ? (
                        visibleUsers.map((user, index) => (
                            <div key={index} className="flex items-center p-3 border-b">
                                {/* Profile Image with Reaction Icon */}
                                <div className="relative">
                                    <img
                                        src={user.profileImg ? DOMAIN_URL + user.profileImg : IMAGE_PATHS.USER}
                                        className="w-[50px] h-[50px] rounded-full border border-gray-300"
                                        alt="User Avatar"
                                    />
                                    {/* Dynamic Reaction Icon */}
                                    <span className="absolute bottom-0 right-0 w-5 h-5 flex items-center justify-center bg-white rounded-full border border-gray-300">
                                        {selectedVotes?.type === "upvote" ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 375 374.999991"
                                                width="20"
                                                height="20"
                                                fill="#3b82f6" // Blue if upvoted, gray otherwise
                                            >
                                                <path d="M 187.5 0 C 83.945312 0 0 83.945312 0 187.5 C 0 291.054688 83.945312 375 187.5 375 C 291.054688 375 375 291.054688 375 187.5 C 375 83.945312 291.054688 0 187.5 0 Z M 121.535156 262.117188 C 121.535156 267.046875 117.539062 271.042969 112.609375 271.042969 L 85.832031 271.042969 C 80.902344 271.042969 76.902344 267.046875 76.902344 262.117188 L 76.902344 150.535156 C 76.902344 145.605469 80.902344 141.609375 85.832031 141.609375 L 112.609375 141.609375 C 117.539062 141.609375 121.535156 145.605469 121.535156 150.535156 Z M 304.652344 171.335938 L 283.863281 251.023438 C 280.785156 262.8125 270.136719 271.042969 257.953125 271.042969 L 184.019531 271.042969 C 164.300781 271.042969 148.316406 255.054688 148.316406 235.335938 L 148.316406 156.398438 C 148.316406 146.929688 152.078125 137.847656 158.773438 131.152344 L 210.667969 79.257812 C 213.613281 76.3125 217.601562 74.660156 221.761719 74.660156 C 233.605469 74.660156 241.175781 87.28125 235.601562 97.730469 L 219.34375 128.21875 C 216.109375 134.285156 220.503906 141.609375 227.375 141.609375 L 281.6875 141.609375 C 294.792969 141.609375 305.421875 152.234375 305.421875 165.34375 C 305.417969 167.363281 305.160156 169.378906 304.652344 171.335938 Z" />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 375 374.999991"
                                                width="20"
                                                height="20"
                                                fill="#ef4444"// Red if downvoted, gray otherwise
                                            >
                                                <path d="M 187.5 375 C 291.054688 375 375 291.054688 375 187.5 C 375 83.945312 291.054688 0 187.5 0 C 83.945312 0 0 83.945312 0 187.5 C 0 291.054688 83.945312 375 187.5 375 Z M 253.464844 112.882812 C 253.464844 107.953125 257.460938 103.957031 262.390625 103.957031 L 289.167969 103.957031 C 294.097656 103.957031 298.09375 107.953125 298.09375 112.882812 L 298.09375 224.464844 C 298.09375 229.394531 294.097656 233.390625 289.167969 233.390625 L 262.390625 233.390625 C 257.460938 233.390625 253.464844 229.394531 253.464844 224.464844 Z M 70.347656 203.664062 L 91.136719 123.976562 C 94.214844 112.1875 104.863281 103.957031 117.046875 103.957031 L 190.980469 103.957031 C 210.699219 103.957031 226.683594 119.945312 226.683594 139.664062 L 226.683594 218.601562 C 226.683594 228.070312 222.921875 237.152344 216.226562 243.847656 L 164.332031 295.742188 C 161.386719 298.6875 157.398438 300.339844 153.238281 300.339844 C 141.394531 300.339844 133.824219 287.71875 139.398438 277.269531 L 155.65625 246.78125 C 158.890625 240.714844 154.496094 233.390625 147.625 233.390625 L 93.3125 233.390625 C 80.207031 233.390625 69.578125 222.765625 69.578125 209.65625 C 69.582031 207.636719 69.839844 205.621094 70.347656 203.664062 Z" />
                                            </svg>
                                        )}

                                    </span>
                                </div>

                                {/* User Details */}
                                <div className="flex-1 ml-3">
                                    <p className="text-gray-800 font-semibold">{user.name}</p>
                                    <p className="text-sm text-gray-500">{user.location}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-500 text-center py-4">No reactions yet.</p>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};


export default VoteModal;
