import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import InfoTooltip from '../../Components/InfoTooltip'
import { getStarColorClass, getRatingLabel } from '../../Functions/RatingLabelColor';
import StarRating from '../../Components/StarRating'

const ReviewSummarySection = ({ ratings, mainRating, type, scrollToReviews }) => {
    return (
        <section className="pt-[40px] pb-[14px] ">
            <Container>
                <Row className="align-items-baseline">
                    <Col lg={12} md={12} className="mb-6 md:mb-0 ">
                        <h3 className="text-[28px] font-bold text-black mb-0 text-[#000]">Overall Rating <InfoTooltip text="Based on feedback from friends, colleagues, managers, and professors about your skills." /></h3>
                    </Col>

                </Row>
                <Row className="justify-between items-center">
                    {/* Overall Rating Section (Left) */}
                    <Col lg={2} md={12} className="mb-6 md:mb-0 leading-[1.4]">
                        <div>
                            <div className="text-[50px] font-bold leading-[1.2] text-[#000] lt">{ratings.overall_rating.toFixed(1)}</div>
                            <span className="font-medium text-[18px] text-[#000]">
                                {getRatingLabel(ratings.overall_rating)}
                            </span>
                            <div className="flex  mt-0">
                                <StarRating rating={ratings.overall_rating}  starSize={15}  />
                                {/* {[...Array(5)].map((_, index) => (
                                    <span key={index} className={` ${index < ratings.overall_rating ? "yellow-class" : "text-gray-300"}`}>
                                        <i className="fas fa-star"></i>
                                    </span>
                                ))} */}
                            </div>

                            <span className={`text-gray-600 text-[14px]  ${ratings.total_reviews > 0 ? `cursor-pointer hover:underline hover:text-black transition` : ""}`} onClick={scrollToReviews}>{ratings.total_reviews > 0 ? `${ratings.total_reviews} Reviews` : "No Reviews Yet"}</span>
                        </div>
                    </Col>

                    <Col lg={4} md={12} sm={12} className="border-r-2 border-gray-300 leading-[1.8] pr-[45px] bol">
                        {[
                            { label: 'Excellent', value: ratings.excellent, max: ratings.total_reviews },
                            { label: 'Good', value: ratings.very_good, max: ratings.total_reviews },
                            { label: 'Average', value: ratings.average, max: ratings.total_reviews },
                            { label: 'Poor', value: ratings.poor, max: ratings.total_reviews },
                            { label: 'Terrible', value: ratings.terrible, max: ratings.total_reviews },
                        ].map((item, index) => (
                            <div key={index} className="flex items-center justify-between space-x-2">
                                <span className="text-[#000] text-[14px] w-[80px]">{item.label}</span>
                                <div className="flex-1 h-[12px] bg-gray-200 rounded-full overflow-hidden">
                                    <div className="h-full yellow-b" style={{ width: `${item.max ? (item.value / item.max) * 100 : 0}%` }}></div>
                                </div>
                                <span className="text-[#000] text-[14px] w-[40px] text-start pl-[8px]">{item.value}</span>
                            </div>
                        ))}
                    </Col>
                    <Col lg={6} md={12} sm={12} className="pl-[45px] rpl">
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-y-3 gap-x-4">
                            {[
                                { label: 'Core Skills', rating: mainRating.core.toFixed(1) },
                                { label: 'Creativity & Innovation', rating: mainRating.creativity.toFixed(1) },
                                { label: 'Personal Effectiveness', rating: mainRating.personal.toFixed(1) },
                                { label: 'Technical & Analytical Skills', rating: mainRating.technical.toFixed(1) },
                                { label: 'Interpersonal Skills', rating: mainRating.interpersonal.toFixed(1) }
                            ].map((item, index) => (
                                <div key={index} className="flex flex-col justify-center items-start leading-[1]">
                                    <div className="leading-[1.4] flex items-center h-[20px]">
                                        <span className="font-medium text-[14px] text-[#000]">{item.label}</span>
                                    </div>
                                    <div className="flex items-center w-full">
                                        <div className="w-[78%] h-[12px] bg-gray-200 rounded-full overflow-hidden mr-2">
                                            <div className="h-full yellow-b" style={{ width: `${(item.rating / 5) * 100}%` }}></div>
                                        </div>
                                        <div>
                                            <span className="text-[#000] text-[14px]">{item.rating}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>


                </Row>
            </Container>
        </section>
    )
}

export default ReviewSummarySection
